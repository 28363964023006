import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { 
    Dinesh_Kumar,
    Mrs_Vaishnavi_Narayanan,
    S_Subramaniam,
    Dinesh_Kumar_detailImg,
    Mrs_Vaishnavi_Narayanan_detailImg,
    S_Subramaniam_detailImg,
    _J_Ajith_Kumar_detailImg,
    _S_ARAVINDHA_KRISHNAN_detailImg,
    _Yogeshraj_I_detailImg,
    _Dr_Gurumoorthy_detailImg,
    _Dr_P_Jothi_detailImg,
    _K_NARENDIRAN_detailImg,
    _Mrs_Lalitha_detailImg,
} from "../../../dashboard/src/assets";

export const configJSON = require("./config");


export interface Props {
    navigation: any;
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    data: any;
    selectedIndex: number;
    crewList: any;
    isOpenEducatorDetails: boolean;
    selectedTestimonial: any;
    selectedEducator: any;
    leaderShipCrewList: any;
    academicCrewList: any;
    operationsCrewList: any;
    selectedCrewList: any;
    teamList: any;
   
}

interface SS {
    id: any;
}

export default class FacultiesController extends BlockComponent<Props, S, SS> {
    getTeamListID: string=""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.get_team_list = this.get_team_list.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

        this.state = {
            txtInputValue: "",
            teamList: [],
            txtSavedValue: "A",
            enableField: false,
            data: {},
            selectedIndex: 0,
            crewList: [
                {
                  key: 0,
                  label: "Leadership Crew",
                  detail: "50+ Teachers",
                },
                {
                  key: 1,
                  label: "Academic Crew",
                  detail: "50+ Teachers",
                },
                {
                  key: 2,
                  label: "Operations Crew",
                  detail: "50+ Teachers",
                },
            ],
            
            isOpenEducatorDetails: false,
            selectedTestimonial: {},
            selectedEducator: {
                id: "1",
                teacherName: "Vijay Sinha",
                qualifications: "M Tech , IIT Bombay",
                experience: "18 years experience of teaching.Mathematics graduation from Delhi University",
                no_of_likes: 3000,
            },
            leaderShipCrewList: [
                {
                    id: 1,
                    profileImage: Dinesh_Kumar,
                    profileDetailsImage: Dinesh_Kumar_detailImg,             
                    name: "Dinesh Kumar",
                    role: "Co-founder",
                    Qualification: "Master's degree(Computer Science), Bachelor of Engineering (BE)(Computer Science)",
                    experience: "9 years and 4 months of experience in software",
                    about: "EDUCATION FOR ALL. This is the mission that drives Dinesh Kumar. As a Silicon Valley professional and USC alumni with over ten years of experience working for companies like NVIDIA, HP, and various other startups, he possesses the expertise necessary to build a competitive yet enriching learning platform. He is passionate about technology and is particularly invested in using it to solve the genuine issues we face in the world today."
                },
                {
                    id: 2,
                    profileImage: "",
                    name: "Sunitha",
                    role: "Academic Director",
                    Qualification: "M.Sc, B.Ed",
                    experience: "16 years of experience in education",
                    about: "Sunitha is a highly skilled and overtly student-focused educational leader possessing a strong commitment to the development of students with the experience of 16 years in the Field of Curriculum development and learning methodologies. Started the career as a teacher in 2005. Since then, has been working in various institutions as an educationist and received the Best Teacher award 2015 from CBSE board. Through workshop trained 300+ teachers on Art of Questioning, Thinking routines, Making Learning visible, Assessment through Blooms Taxonomy, 21st Century skills & Academic rigour. Experienced in creating and executing Interdisciplinary and Cross-Curricular Connect. Deployed imbibing Ron Richard’s thinking routines (project zero) and understanding map as academic routines to instill thinking minds among students. Worked in building emotional and physical awareness in primary school and best career prospects to students in senior school. Strongly believes in “the function of education is to teach one to think and imbibe life skills”.",
                    certifications: [ "International certificate in Education (ICEPT) – Asian International College, Singapore" ],
                    subject: [ "Computer Science" ],
                    achievements: [
                        "Best Teacher Award from CBSE",
                        "Best Presentation award – Academic Progression strategies 2019 for Concept consolidation & Application"
                    ],
                },
                {
                    id: 3,
                    profileImage: Mrs_Vaishnavi_Narayanan,
                    profileDetailsImage: Mrs_Vaishnavi_Narayanan_detailImg, 
                    name: "Vyshnavi Narayanan",
                    role: "Co-founder",
                    Qualification: "",
                    experience: "",
                    about: "Vysh is an alumna of Academy of Arts, San Francisco and has experience working for a Fortune 100 company. She has a passion for product design and wants to use her creative abilities in the field of education. She has a keen eye for detail and has won awards for her designs. She has established a strong track record in conceptualizing innovative product designs and loves to experiment with creative processes. She wants to combine her passion and skills and help people learn through new educational programs."
                },
                {
                    id: 4,
                    profileImage: S_Subramaniam,
                    profileDetailsImage: S_Subramaniam_detailImg,
                    name: "S.Subramaniam",
                    Qualification: "MA Business Economics",
                    experience: "33 Years of experience",
                    about: "A Seasoned Sales Professional in the field of Education Technology, who works with School, Colleges & Universities across India to upscale ICT interface in the field of Education."
                }
            ],
            academicCrewList: [
            
            ],
            operationsCrewList: [
            ],
            selectedCrewList: [],
        };
       
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }



    async componentDidMount() {
        this.setState({ selectedCrewList: this.state.leaderShipCrewList });

        const { crewList } = this.state;   
        crewList[0].detail = `${this.state.leaderShipCrewList?.length} Teachers`;
        crewList[1].detail = `${this.state.academicCrewList?.length} Teachers`;
        crewList[2].detail = `${this.state.operationsCrewList?.length} Teachers`;
        this.setState({ crewList: this.state.crewList });
        this.get_team_list();
    }

    get_team_list = () => {
        const header = {
          "Content-Type": configJSON.dashboarContentType,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTeamListID = requestMessage.messageId;
        let apiEndPoint = configJSON.TeamEndpoint;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          apiEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestbaseURLMessage),
          configJSON.baseURL
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
    
    
            if (apiRequestCallId === this.getTeamListID) {
                if (responseJson != null) {
                    if (!responseJson.errors) {
                        this.setState({ teamList: responseJson })
                        console.log('Team List', this.state.teamList)
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
          }
    
            if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
                let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    
                this.showAlert(
                    "Change Value",
                    "From: " + this.state.txtSavedValue + " To: " + value
                );
    
                this.setState({ txtSavedValue: value });
            }
           
        }
    }

    
    txtInputWebProps = {
        onChangeText: (text: string) => {
            this.setState({ txtInputValue: text });
        },
        secureTextEntry: false,
    };

    txtInputMobileProps = {
        ...this.txtInputWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address",
    };

    txtInputProps = this.isPlatformWeb()
        ? this.txtInputWebProps
        : this.txtInputMobileProps;

    handleSelectTab = (key: number) => {
        if (key === 0) {
            this.setState({ selectedCrewList: this.state.teamList.leadership });
        } else if (key === 1) {
            this.setState({ selectedCrewList: this.state.teamList.academic });
        } else if (key === 2) {
            this.setState({ selectedCrewList: this.state.teamList.operational });
        }
        this.setState({ selectedIndex: key });
    };

    getJustifyContent = (index: number) => {
        if (index === 0) {
            return "flex-start";
        } else if (index === 2) {
            return "flex-end";
        } else {
            return "center";
        }
    };

   
}
