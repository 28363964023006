import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  data: any;
  technologyList: any;
}

interface SS {
  id: any;
}

export default class TechnologyController extends BlockComponent<Props, S, SS> {
  getTechnologyListID: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.get_technology_list = this.get_technology_list.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      technologyList: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      data: {},
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.get_technology_list();
  }

  get_technology_list = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTechnologyListID = requestMessage.messageId;
    let apiEndPoint = configJSON.technologyEndpoint + `?site_page=technology`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getTechnologyListID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ technologyList: responseJson.data.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }

      if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
        let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

        this.showAlert(
          "Change Value",
          "From: " + this.state.txtSavedValue + " To: " + value
        );

        this.setState({ txtSavedValue: value });
      }
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;
}
