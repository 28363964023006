import * as React from "react";
import { Box, Typography, IconButton, Divider } from "@material-ui/core";
import LinkItem from "../../LinkedItem.web";
import { call, email, logoSidePlaced } from "../../assets";
import SocialMedia from "../../SocialMedia.web";
import "./styles/Footer.style.css";
import "../../LandingPage.web.css";
import FooterContoller from "./FooterController.web";

// export interface IAppProps {
// }

// const Degress = ['JEE', 'NEET', 'CBSE', 'STATE BOARD']
// const OtherLinks = [
//     {
//         link : "Blog",
//         path : "/Blog"
//     }
// ]

export default class Footer extends FooterContoller {
  render() {
    // const Classes = useStylesFooter()
    const footerList = this.state.footerList;
    const footerDescription = footerList?.attributes?.description 
    const footerLinks = footerList?.attributes?.links

    console.log(footerLinks,"footerLinks")
    const footerCourses = footerList?.attributes?.courses
    const footerContactDetails = footerList?.attributes?.contact_details?.data?.attributes
    

    return (
      <>
        <Box
          className="footerWrapper"
          p={{ xs: "1.5rem", sm: "3rem", md: "5rem" }}
          display={{ xs: "block", sm: "flex", md: "flex", lg: "flex" }}
        >
          {/* 5 columns */}
          <Box
            className="innerFooterLeft"
            width={{ xs: "100%", sm: "35%", md: "35%", lg: "35%" }}
            // p={{ sm: "0rem", md: "2rem", lg: "2rem" }}
            pt={{ sm: "0rem", md: "0rem", lg: "0rem" }}
            mb={2}
            mr={{ sm: "2rem", md: "20px", lg: "100px" }}
          >
            <img className="footerLogo" src={logoSidePlaced} alt="Logo" />
            <Typography className="Learn-from-education-footer">
              {footerDescription}
            </Typography>
          </Box>
          <Box
            className="innerFooterRight"
            width={{ xs: "100%", md: "65%", lg: "65%" }}
          >
            <Box width={{ xs: "50%", sm: "50%", md: "25%", lg: "25%" }}>
              <Typography variant="h5" className="Links-header-text">
                Links
              </Typography>
              {footerLinks?.map((item: any) => (
                
              <LinkItem data={item} onClick={item === "Technology" ? "/Technology" : item === "Competitive exam" ? "/Competitive-exams" : item === "About us" ? "/AboutUs" : item === "Our team" ? "/Faculties" : item === "Blog" ? "/Blogs" : item === "Tuitions" ?  "/Tuitions" : item === "Test series" ? "/Test-series" : "/"} />
              ))}
              {/* <LinkItem data="About" onClick="/AboutUs" /> */}
              {/* <LinkItem data="Careers" /> */}
            </Box>
            <Box width={{ xs: "50%", sm: "50%", md: "25%", lg: "25%" }}>
              <Typography variant="h5" className="Links-header-text">
                Courses
              </Typography>
              {/* {footerCourses?.map((item: any) => (
                ))} */}
                <LinkItem data="JEE" onClick={"/Competitive-exams/Jee"} />
               <LinkItem data="NEET" onClick={"/Competitive-exams/Neet"} />
              <LinkItem data="CBSE" onClick={"/Tuitions/CBSE"} />
              <LinkItem data="STATE BOARD" onClick={"/Tuitions/TN-Board"} /> 
            </Box>
            <Box
              width={{ xs: "50%", sm: "50%", md: "25%", lg: "25%" }}
              mt={{ xs: "25px", sm: "25px", md: "0px", lg: "0px" }}
            >
              <Typography variant="h5" className="Links-header-text">
                Other Links
              </Typography>
              {/* <LinkItem data="Blog" onClick="/Blogs" /> */}
              {/* <LinkItem data="Privacy" onClick="/PrivacyPolicy" /> */}
              <LinkItem data="Terms & Privacy" onClick={"/PrivacyPolicy"} />
              {/* <LinkItem data="Telegram" /> */}
            </Box>
            <Box
              width={{ xs: "50%", sm: "50%", md: "25%", lg: "25%" }}
              mt={{ xs: "25px", sm: "25px", md: "0px", lg: "0px" }}
            >
              <Typography variant="h5" className="Links-header-text" style={{marginBottom: 20}}>
                Get In Touch
              </Typography>
              <Box className="getInTouchWrapper">
                <IconButton
                  color="primary"
                  aria-label="add to shopping cart"
                  className="get-in-touch-padding"
                >
                  <img src={call} className="call-email-img" />
                </IconButton>
                <Typography className="get-in-touch-item-text">
                  <a
                    href="tel:+91 9513317652"
                    className="get-in-touch-item-text"
                  >
                    {footerContactDetails?.contact_number}
                  </a>
                </Typography>
              </Box>
              <Box className="getInTouchWrapper">
                <IconButton
                  color="primary"
                  aria-label="add to shopping cart"
                  className="get-in-touch-padding"
                >
                  <img src={email} className="call-email-img" />
                </IconButton>
                <Typography className="get-in-touch-item-text">
                  <a
                    href="mailto:hello@teachze.com"
                    className="get-in-touch-item-text"
                  >
                    {footerContactDetails?.email}
                  </a>
                </Typography>
              </Box>
              <SocialMedia />
            </Box>
          </Box>
        </Box>
        <Divider className="divider" />
        <Box className="reservedRights">
          <Typography className="All-rights-reserve-text">
            © 2022 All rights reserved.
          </Typography>
        </Box>
      </>
    );
  }
}
