import React from "react";
import { Button, Typography, Grid, Divider, Tabs } from "@material-ui/core";
import LandingPageController from "../LandingPage/LandingPageController.web";
import {
  examEnable,
  onlineLearning,
  bitmap1,
  governmentDisable,
  presentation,
  rightArrow,
  leftArrow,
  aboutneet,
  quote,
  developerActivity,
  JEETS_21,
  NEETTS_21,
} from "../assets";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "../CompetitiveExamPage/CompetetiveExamPage.web.css";
import { HISTORY } from "../../../../components/src/common";
import ZohoFormModalPopup from "../../../CustomForm/src/ZohoRegistrationFormModalPopup/ZohoFormModalPopup.web";
import ThankYou from "../../../CustomForm/src/ThankYou/ThankYou.web";

const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;
const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <img src={rightArrow} />,
  prevArrow: <img src={leftArrow} />,
  style: {
    padding: "75px 0",
  },
  responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
        arrows: true,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Reveal = require("react-reveal/Reveal");

class TestSeriesPage extends LandingPageController {

  render() {
    const {
      courseDetailsTestSeries,
      courseDetailsTestSeriesJee,
      courseHighlights,
      teachzePoints,
      userDetails,
      tabsListTestSeries,
      selectedIndexTestSeries,
      filteredTabsListTestSeries,
      courseList,
    } = this.state;
    const push = (item: any, type: any, admission: any) => {
      HISTORY.push({
        pathname: "/CourseDetails",
        state: {
          program: type,
          admission: admission,
          courseInfo: item.courseDetails,
          courseName: item.courseName,
          testimonials: [...this.state.userDetails],
          isFrom: "testseries",
        },
      });
    };
    const handleMetaTags = () =>{
      if(selectedIndexTestSeries === 0){
        return (
          <Helmet>
          <title>NEET, JEE Online Test Series – Teachze LIVE Online Coaching Classes</title>
          <meta name="description" content="Practice and prepare effectively with test series and mock tests at Teachze. If you are a NEET/ JEE aspirant, then this will help you to train better and perform well at the competitive exams. These test series of Teachze are ideal for students who are thorough with their syllabus and expects to put the preparation on final gear by sharpening their exam skills. " />
         </Helmet>
        )
      } else if(selectedIndexTestSeries === 1){
        return (
          <Helmet>
          <title>Online Test Series for NEET – Teachze LIVE Online Coaching Classes</title>
          <meta name="description" content="Put your NEET preparations in Final Gear with Teachze NEET test series. Teachze live online coaching classes help you get trained with efficient preparation strategies and techniques to sharpen your exam skills and achieve top rank in NEET with a little warm ups. You will get assistance in this structured practice process from our professional NEET faculties. Enroll now for NEET mock test series and NEET compact test series." />
         </Helmet>
        )
      }else  {
        return (
          <Helmet>
          <title>Online Test Series for JEE – Join Teachze LIVE Online Coaching Classes</title>
          <meta name="description" content="Put your JEE preparations in Final Gear with Teachze JEE test series. Teachze live online coaching classes help you get trained with efficient preparation strategies and techniques to sharpen your exam skills and achieve top rank in JEE with a little warm ups. You will get assistance in this structured practice process from our professional JEE faculties. Enroll now for JEE mock test series and JEE compact test series." />
         </Helmet>
        )
      }


    }
     
    const testPageTitle = courseList?.attributes?.title
    const testPageDescription = courseList?.attributes?.description
    const userTestimonials = courseList?.attributes?.courses[0]?.user_commnets
    const classroomSectionTitle = courseList?.attributes?.classroom?.data?.attributes?.title
    const classroomSectionDescription = courseList?.attributes?.classroom?.data?.attributes?.description
    const whyTeachZeSectionDescription = courseList?.attributes?.why_teachze?.description
    const testSeriesNeet = courseList?.attributes?.courses[0]?.courses?.data
    const testSeriesJee = courseList?.attributes?.courses[1]?.courses?.data
    console.log("testSeriesNeet", testSeriesNeet);
    

    return (
      <div style={root}>
        {handleMetaTags()}
        <Grid className="relative" container>
          <Grid className="Competitive-container"
            item
            xs={12}
            
          >
            <Reveal effect="fadeInUp">
              <div className="width-70per">
                <Grid container>
                  <Grid className="Competetive-info-left"
                    item
                    xs={12}
                    sm={8}
                    md={8}
                   
                  >
                    <Typography  variant="h1"  className="Lets-Competetive-Ex">
                      {testPageTitle}
                    </Typography>
                    <Typography className="textlabel_description_1">
                      {testPageDescription}
                    </Typography>

                    <Grid className="Search-Bg-Rectangle">
                      <div className="Search-Rectangle">
                        <img title="Search for course" src={bitmap1} alt="Search icon" className="Search-Img" />
                      </div>
                      <input
                        placeholder="Search for course"
                        className="Search-for-course"
                        value={this.state.inputTextTestSeries}
                        onChange={(e) => {
                          this.setState({
                            inputTextTestSeries: e.target.value,
                          });
                          if (
                            e.target.value === "" ||
                            e.target.value === null
                          ) {
                            this.setState({
                              filteredTabsListTestSeries: tabsListTestSeries,
                              selectedIndexTestSeries: 0,
                            });
                          }
                        }}
                      />
                      <Button
                        className="Search-Btn"
                        onClick={this.searchTestSeriesCourseClick}
                      >
                        <Typography className="Search-Text">Search</Typography>
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <img
                      src={onlineLearning}
                      alt="Student learning" title="Competitive Exam"
                      className="online-learning-img"
                    />
                  </Grid>
                </Grid>
              </div>
            </Reveal>
          </Grid>

          <Grid className="justifyContent-center"
            item
            xs={12}
            
          >
            <div className="width-70per">
              <Grid container>
                <Reveal effect="fadeInUp">
                  <Typography variant="h2" className="Courses">Courses</Typography>
                </Reveal>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                      <Reveal effect="fadeInLeft left cascade">
                        <div className="Courses-Rectangle">
                          <Grid container>
                            <Grid className="xs-4"
                              item
                              xs={4}
                              md={4}
                              
                            >
                              <img
                                src={governmentDisable}
                                className="Courses-img" title="Competetive Exam" alt="image competetive"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              md={8}
                              onClick={() =>
                                this.props.handleSelect("/Competitive-exams", 1)
                              }
                            >
                              <Typography className="Courses-Text">
                                Competitive Exam
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid container>
                            <Grid className="xs-4"
                              item
                              xs={4}
                              md={4}
                              
                            >
                              <img src={presentation} className="Courses-img" title="Tuitions" alt="image tuitions"/>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              md={8}
                              onClick={() =>
                                this.props.handleSelect("/Tuitions", 2)
                              }
                            >
                              <Typography className="Courses-Text">
                                Tuitions
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid container>
                            <Grid className="xs-4"
                              item
                              xs={4}
                              md={4}
                              
                            >
                              <img src={examEnable} className="Courses-img" title="Test Series" alt="image test"/>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              md={8}
                              onClick={() =>
                                this.props.handleSelect("/Test-series", 3)
                              }
                            >
                              <Typography
                                className="Courses-Text"
                                style={{ color: "#5580ea" }}
                              >
                                Test Series
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </Reveal>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      <Reveal effect="fadeInRight right cascade">
                        <Grid container direction="row">
                          {selectedIndexTestSeries === -1 ? (
                            <Grid>
                              <Typography className="cascade-Name"
                               
                              >
                                No course found.
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid className="direction-column relative"
                              item
                              xs={12}
                              
                            >
                              <Tabs className="secondary-tabs"
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                                variant="scrollable"
                                scrollButtons="auto"
                                TabScrollButtonProps={{
                                  
                                }}
                              >
                                {filteredTabsListTestSeries?.map(
                                  (item: any) => (
                                    <Grid className="filteredTabsList"
                                      style={{
                                       
                                        backgroundColor:
                                          item.key === selectedIndexTestSeries
                                            ? "#5580ea"
                                            : "#ecefff",
                                       
                                      }}
                                      key={item.key}
                                      onClick={() => {
                                        this.handleSelectTabTestSeries(
                                          item.value,
                                          item.key
                                        );
                                      }}
                                    >
                                      <Typography className="innertabs"
                                        style={{
                                          
                                          color:
                                            item.key === selectedIndexTestSeries
                                              ? "white"
                                              : "#5580ea",
                                        }}
                                      >
                                        {item.label}
                                      </Typography>
                                    </Grid>
                                  )
                                )}
                              </Tabs>
                            </Grid>
                          )}
                        </Grid>
                        {selectedIndexTestSeries === 0 ||
                        selectedIndexTestSeries === 1 ? (
                          <>
                            <Grid container>
                              <Typography  variant="h3"
                                className="Courses-Header"
                                style={{ cursor: "pointer" }}
                              >
                                NEET Test Series
                              </Typography>
                            </Grid>

                            <Grid container direction="row">
                              <Grid className="direction-row"
                                item
                                xs={12}
                                
                              >
                                <Grid container>
                                  {testSeriesNeet &&
                                    testSeriesNeet.map((item: any) => (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        className="paddingRight20"
                                      >
                                        <Grid
                                          className="Courses-Details"
                                          onClick={() =>
                                            HISTORY.push({
                                              pathname: "/CourseDetails",
                                              state: {
                                                program: "NEET Course",
                                                
                                                Id: item.id,
                                                courseName: item?.attributes?.course_name,
                                                testimonials: [
                                                  ...this.state.userDetails,
                                                ],
                                                isFrom: "competitive",
                                              },
                                            })
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img className="image-course" title="NEET Course" alt="NEET Test Series"
                                            src={NEETTS_21} 
                                           
                                          ></img>
                                          <Typography  variant="h4"  className="Course-Name">
                                            {item?.attributes?.course_name}
                                          </Typography>
                                          <Grid
                                           
                                            className="less-margin-small-screen"
                                          >
                                            <Typography className="Know-More-Text">
                                              Know More
                                            </Typography>
                                            <img className="Arrows" alt="Arrow"
                                              src={rightArrow}
                                              
                                            ></img>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    ))}
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}

                        {selectedIndexTestSeries === 0 ||
                        selectedIndexTestSeries === 2 ? (
                          <>
                            <Grid container>
                              <Typography variant="h3"
                                className="Courses-Header"
                                style={{ cursor: "pointer" }}
                              >
                                JEE Test Series
                              </Typography>
                            </Grid>

                            <Grid container direction="row">
                              <Grid className="direction-row"
                                item
                                xs={12}
                                
                              >
                                <Grid container>
                                  {testSeriesJee &&
                                    testSeriesJee.map(
                                      (item: any) => (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          md={4}
                                          className="paddingRight20"
                                        >
                                          <Grid
                                            className="Courses-Details"
                                            onClick={() =>
                                              HISTORY.push({
                                                pathname: "/CourseDetails",
                                                state: {
                                                  program: "NEET Course",
                                                  
                                                  Id: item.id,
                                                  courseName: item?.attributes?.course_name,
                                                  testimonials: [
                                                    ...this.state.userDetails,
                                                  ],
                                                  isFrom: "competitive",
                                                },
                                              })
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img className="image-course" title="JEE Course" alt="JEE Test Series"
                                              src={JEETS_21}
                                             
                                            ></img>
                                            <Typography  variant="h4"  className="Course-Name">
                                              {item?.attributes?.course_name}
                                            </Typography>
                                            <Grid
                                              
                                              className="less-margin-small-screen"
                                            >
                                              <Typography className="Know-More-Text">
                                                Know More
                                              </Typography>
                                              <img className="Arrows" alt="Arrow"
                                                src={rightArrow}
                                                
                                              ></img>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )
                                    )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Reveal>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="spacing-100 course-highlight"
            
          >
            <div className="width-70per">
              <Grid container>
                <Grid item xs={12}>
                  <div className="Course-Highlights-card">
                    <Reveal effect="fadeInUp">
                      <Grid className="direction-column justifyContent-center"
                        container
                        
                      >
                        <Typography variant="h2" className="Course-Highlights">
                          Course Highlights
                        </Typography>
                        <Typography variant="h3"
                          className="Get-assistance-text"
                          style={{ margin: "2% 0" }}
                        >
                          Get assistance in structured learning process from
                          expert faculty
                        </Typography>

                        <Grid
                          container
                          spacing={7}
                          alignItems="center"
                          style={{ marginTop: "20px" }}
                        >
                          {courseHighlights &&
                            courseHighlights.map((item: any) => (
                              <Grid item md={4}>
                                <img className="image-faculty" alt="image faculty"
                                  src={item.image}
                                 
                                ></img>
                                <Typography
                                  style={{
                                   
                                    fontSize: "22px",
                                   
                                    width:
                                      item.text ===
                                      "Face-to-Face live classes with personalized learning"
                                        ? "105%"
                                        : "98%",
                                  }}
                                  className="md-font-18 xs-font-16 faculty-info text-left"
                                >
                                  {item.text}
                                </Typography>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Reveal>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {selectedIndexTestSeries === 1 && (
            <Grid
              item
              xs={12}
              className="spacing-100 Competitive-container-info"
             
            >
              <div className="width-70per">
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <Reveal effect="fadeInLeft left cascade">
                      <img title="About NEET" alt="about image" src={aboutneet} style={{ width: "80%" }}></img>
                    </Reveal>
                  </Grid>
                  <Grid className="Competetive-info-left"
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    
                  >
                    <Reveal effect="fadeInRight right cascade">
                      <Typography variant="h2" className="About-NEET-Text">
                        About NEET
                      </Typography>
                      <Typography className="National-Eligibility-Text">
                        {courseList?.attributes?.courses[0]?.course_details?.data?.attributes?.description}
                      </Typography>
                      <Button
                        className="book-trial-btn-small"
                        onClick={this.handleOpenZohoFormPopup}
                      >
                        <Typography
                         
                          className="md-font-18 xs-font-16 start-learning text-center"
                        >
                          Start Learning
                        </Typography>
                      </Button>
                    </Reveal>
                  </Grid>
                </Grid>

              
              </div>
            </Grid>
          )}
          {selectedIndexTestSeries === 0 ||
            (selectedIndexTestSeries === 2 && (
              <Grid
                item
                xs={12}
                className="spacing-100 Competitive-container-info"
                
              >
                <div className="width-70per">
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Reveal effect="fadeInLeft left cascade">
                        <img title="About NEET" alt="about image" src={aboutneet} style={{ width: "80%" }}></img>
                      </Reveal>
                    </Grid>
                    <Grid className="Competetive-info-left"
                      item
                      xs={12}
                      sm={12}
                      md={6}
                     
                    >
                      <Reveal effect="fadeInRight right cascade">
                        <Typography variant="h2" className="About-NEET-Text">
                          About JEE
                        </Typography>
                        <Typography className="National-Eligibility-Text">
                          {courseList?.attributes?.courses[1]?.course_details?.data?.attributes?.description}
                          
                        </Typography>
                        <Button
                    
                          className="book-trial-btn-small"
                          onClick={this.handleOpenZohoFormPopup}
                        >
                          <Typography
                           
                            className="md-font-18 xs-font-16 start-learning text-left"
                          >
                            Start Learning
                          </Typography>
                        </Button>
                      </Reveal>
                    </Grid>
                  </Grid>

                 
                </div>
              </Grid>
            ))}
          <Grid className="dis-jcenter"
            item
            xs={12}
            
          >
            <div className="width-70per">
              <Reveal effect="fadeInUp">
                <Grid
                  container
                  className="spacing-100 direction-column"
                  
                >
                  <Typography variant="h2" className="Course-Highlights">
                    Why Teachze?
                  </Typography>
                  <Typography variant="h3"
                    className="Get-assistance-text"
                    style={{ margin: "2% 0" }}
                  >
                    {whyTeachZeSectionDescription}
                  </Typography>
                  <Grid item xs={12} style={{ marginTop: "2%" }}>
                    <Grid container spacing={6}>
                      {teachzePoints &&
                        teachzePoints.map((item: any) => (
                          <Grid item md={6}>
                            <Grid className="direction-row"
                              container
                              
                            >
                              <Grid item xs={2} sm={2} md={2} lg={1}>
                                <img className="img-source" alt="Online Live Classes"
                                  src={item.imgSource}
                                 
                                ></img>
                              </Grid>
                              <Grid
                                item
                                xs={10}
                                sm={10}
                                md={10}
                                lg={11}
                                style={{ paddingLeft: "1%" }}
                              >
                                <Typography variant="h4"
                                 
                                  className="md-font-20 sm-font-18 small-title"
                                >
                                  {item.header}
                                </Typography>
                                <Typography
                                 
                                  className="xs-font-14 small-title small-title1"
                                >
                                  {item.text}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Reveal>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="spacing-100 Competitive-container-info"
            
          >
            <Grid container>
              <Grid className="pl"
                item
                xs={12}
                sm={12}
                md={8}
                
              >
                <Reveal effect="fadeInLeft left cascade">
                  <div className="padding-right">
                    <Typography  variant="h2" className="About-NEET-Text">
                      {classroomSectionTitle}
                    </Typography>
                    <Typography variant="h3" className="This-platform-will-text">
                      {classroomSectionDescription}
                    </Typography>
                    <ul>
                      <li className="view_box_content_text_li_2">
                        All Devices
                      </li>
                      <li className="ulList_2">
                        Get unlimited learning and study content from any
                        device, including mobile, tablet, and laptop.
                      </li>
                      <li className="view_box_content_text_li_2">
                        Track Your Progress
                      </li>
                      <li className="view_box_content_text_li_2">
                        Anywhere Access
                      </li>
                    </ul>
                    <Typography className="This-platform-will-text">
                      
                    </Typography>
                    <Button
                      
                      className="book-trial-btn-small"
                      onClick={this.handleOpenZohoFormPopup}
                    >
                      <Typography className="start-learning"
                       
                      >
                        Start Learning
                      </Typography>
                    </Button>
                  </div>
                </Reveal>
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="bitmap-img-align">
                <Reveal effect="fadeInRight right cascade">
                  <img src={developerActivity} className="bitmap-img" alt="Bitmap"></img>
                </Reveal>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="dis-jcenter ptop3"
            item
            xs={12}
            
          >
            <div className="width-70per">
              <Grid container style={{ position: "relative" }}>
                <Reveal effect="fadeInUp">
                  <img src={quote} className="quote-img"></img>
                  <Typography  variant="h2" className="Hear-from-text">
                    Hear from our students and their parents
                  </Typography>
                </Reveal>
              </Grid>
             

              <Slider className="tiltImportant landing-carousel" {...settings}>
                {userTestimonials &&
                  userTestimonials.map((item: any) => (
                    <Grid style={{ padding: "5px" }}>
                      <Grid item xs={12} sm={12} md={12} className="view_box">
                        <Grid container>
                         
                          <Grid className="Competetive-info-left"
                            item
                            xs={8}
                            sm={8}
                            md={8}
                            style={{
                             
                              paddingLeft: 10,
                            }}
                          >
                            <Typography variant="h5" className="view_name">
                              {item.user_name}
                            </Typography>
                            <Typography variant="h6" className="view_parent">
                              
                            </Typography>
                          </Grid>
                        </Grid>

                        <div className="view_line"></div>
                        <Typography className="view_text">
                          {item.comment}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
        <ZohoFormModalPopup
          open={this.state.openZohoForm}
          openThankyouModal={this.handleClickOpen}
          closeThankYou={this.handleClose}
          onCloseModal={this.handleCloseZohoFormPopup}
          isFirstTime={true}
        />
        <ThankYou open={this.state.open} handleClose={this.handleClose} />
      </div>
    );
  }
}

export const competetiveExamPageStyles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ebebeb",
      },
    },
  },
};

export default TestSeriesPage;
