// Customizable Area Start
import React from "react";
import Lottie from "react-lottie-player";
// import  * as lottieJson from "../../landingpage_gif.json" ;
var lottieJson = require('../../landingpage_gif.json');

import LandingPageController from "./LandingPageController.web";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import { Helmet } from "react-helmet";
import {
  developerActivity,
  book1,
  onlineClass,
  elearning,
  videoPlayer,
  groups,
  rightArrow,
  leftArrow,
  Anywhere_learning,
  Self_paced_Learning_img,
  Simplistic_Approach_img,
  Thought_Transparency_img,
  Interactive_Advanced_Platform_img,
  Detailed_Knowledge_Base_img,
} from "../assets";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./LandingPage.style.css";
import "../LandingPage.web.css";
import ZohoFormModalPopup from "../../../CustomForm/src/ZohoRegistrationFormModalPopup/ZohoFormModalPopup.web";
import MiniCard from "../Components/InformationCard/MiniCard";
import SubjectTags from "../Components/Tags/SubjectTags.web";
import CarouselCard from "../Components/InformationCard/CarouselCard/CarouselCard.web";
import MiniStatsCard from "../Components/InformationCard/Stats/MiniStatsCard";
import ThankYou from "../../../CustomForm/src/ThankYou/ThankYou.web";



const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
  overflow: "hidden",
} as const;

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <img src={rightArrow} />,
  prevArrow: <img src={leftArrow} />,
};

const Reveal = require("react-reveal/Reveal");
// Customizable Area End

export default class LandingPage extends LandingPageController {


  render() {
    const {
      bookOver,
      schoolOver,
      documentOver,
    } = this.state;

    const HomePageData = this.state.LandingPageList.attributes;
    const HomePageTittle = HomePageData?.first_section_item[0]?.name;
    const HomePageDescription = HomePageData?.first_section_item[0]?.description;
    const SectionTitleOne = HomePageData?.second_section_first_item?.title;
    const SectionDescriptionOne = HomePageData?.second_section_first_item?.description;
    const SectionDescriptionTwo = HomePageData?.third_section_item?.description;
    const SectionTitleTwo = HomePageData?.third_section_item?.title;
    const SectionTitleThree = HomePageData?.fourth_section_item[0]?.title;
    const SectionDescriptionThree = HomePageData?.fourth_section_item[0]?.description;
    const SectionClassroomTitle = HomePageData?.classroom_section?.data?.attributes?.title;
    const SectionClassroomDescription = HomePageData?.classroom_section?.data?.attributes?.description;
    const SectionClassroomFeatures = HomePageData?.classroom_section?.data?.attributes?.features;
    const SectionLearningFeatures = HomePageData?.fourth_section_item_list
    const SectionCourseContentList = HomePageData?.second_section_second_item_list
    const SectionCarouselList = HomePageData?.third_section_item_list



    return (
      <>
        <Helmet>
          <title>
            Teachze | Best Live Online Coaching Classes and Online Tuitions
          </title>
          <meta
            name="description"
            content="Join Teachze LIVE Online Classes led by dedicated master tutors ad prepare for your exam more effectively.  Personalized face-to-face online coaching for NEET, JEE, CA | Online Tuitions for classes 6th -12th (CBSE, TN Board) |Book a Free Trial today."
          />
        </Helmet>
        <Box style={root}>
          <Grid container alignItems="center" className="landingPageContainer">
            <Reveal effect="fadeInUp">
              <Box className="landingPageHeader">
                <Typography variant="h1" className="Taking-Forward-The-N">
                  {HomePageTittle}
                </Typography>
                <Typography className="textlabel_header-copy">
                  {HomePageDescription}
                </Typography>
                <Button
                  className="book-trial-btn-small"
                  onClick={this.handleOpenZohoFormPopup}
                >
                  <Typography className="book-trial-text-small">
                    Book Free Trial
                  </Typography>
                </Button>
              </Box>
            </Reveal>

            <Reveal effect="fadeInUp">
              <Lottie
                loop
                animationData={lottieJson}
                play
                className="animation_gif"
              />
            </Reveal>
          </Grid>
          <Grid className="relative" container>
            <Grid item xs={12} className="landingPage-attend-block">
              <Box className="width-70per">
                <Grid className="pb-5" container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    className="center-text-small-screen"
                  >
                    <Reveal effect="fadeInLeft left cascade">
                      <Typography variant="h2" className="Learn-from-Expert">
                        {SectionTitleOne}
                      </Typography>

                      <Typography className="Discover-a-new-way">
                        {SectionDescriptionOne}
                      </Typography>
                    </Reveal>
                    <Reveal effect="fadeInLeft">
                      <Button
                        className="attend-now-btn-small"
                        onClick={this.handleOpenZohoFormPopup}
                      >
                        <Typography className="attend-now-text-small">
                          Attend Now
                        </Typography>
                      </Button>
                    </Reveal>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <Reveal effect="fadeInRight right cascade">
                      {SectionCourseContentList?.map((item: any) => (

                        <MiniCard

                          onMouseHover={item.course_content === "competitive_exam" ? this.competitiveExamCardMouseHover : item.course_content === "tuition" ? this.schoolTutionCardMouseHover : this.examTestSeriesMouseHover}
                          onMouseLeave={item.course_content === "competitive_exam" ? this.competitiveExamCardMouseLeave : item.course_content === "tuition" ? this.schoolTutionMouseLeave : this.examTestSeriesMouseLeave}
                          className={"competitiveExam schoolTuition examTestSeries"}
                          onClick={() => {
                            item.course_content === "competitive_exam" ? this.props.handleSelect("/Competitive-exams", 1) : item.course_content === "tuition" ? this.props.handleSelect("/Tuitions", 2) : this.props.handleSelect("/Test-series", 3)
                          }}
                          imgSrc={item.course_content === "competitive_exam" ? this.state.bookOver : item.course_content === "tuition" ? schoolOver : documentOver}
                        >
                          <Typography variant="h3" className="Competetive-Exam">
                            {item.course_content}
                          </Typography>

                          <Grid className="allRectangles">
                            {item?.course_types?.map((name: any, index: number) => {
                              return (
                                <SubjectTags
                                  key={index * 11}
                                  text={name}
                                  className="examRectangle"
                                  textClassName="competitiveTags"
                                />
                              );
                            })}
                          </Grid>
                        </MiniCard>
                      ))}
                    </Reveal>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid container className="liveClassContainer">
            <Grid item xs={12} className="liveClassHeader">
              <Box className="width-70per">
                <Reveal duration={3000} effect="fadeInUp up cascade">
                  <Typography variant="h2" className="Committed-to-textlabel">
                    {SectionTitleTwo}
                  </Typography>
                  <Typography className="Become-a-part-textlabel">
                    {SectionDescriptionTwo}
                  </Typography>
                </Reveal>
              </Box>
              <Box className="card width-70per">
                <Grid className="center-text-small-screen carouselContainer">
                  <Slider className="landing-carousel" {...settings}>
                    {SectionCarouselList?.map((item: any, index: number) => (
                      <CarouselCard
                        key={index * 2}
                        title={item.name}
                        description={item.description}
                        onClick={this.handleOpenZohoFormPopup}
                        imgSrc={item.name === "Anywhere learning " ? Anywhere_learning : item.name === "self placed learning" ? Self_paced_Learning_img : item.name === "simplistic aproach" ? Simplistic_Approach_img : item.name === "thought transparency" ? Thought_Transparency_img : item.name === "Interactive advance Platform" ? Interactive_Advanced_Platform_img : Detailed_Knowledge_Base_img}
                      />
                    ))}
                  </Slider>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid className="Unleash-outer" container>
            <Grid className="Potential-content" item xs={12}>
              <div className="width-70per">
                <Grid container>
                  <Grid item xs={12}>
                    <Reveal duration={3000} effect="fadeInUp up cascade">
                      <Typography variant="h2" className="startLearningWith">
                        {SectionTitleThree}
                        <span> Teachze</span>
                      </Typography>

                      <Typography className="getUnlimitedAccess">
                        {SectionDescriptionThree}
                      </Typography>
                    </Reveal>
                  </Grid>
                  <Grid className="flex-row" item xs={12}>
                    <Grid container justify="center">

                      {SectionLearningFeatures?.map((item: any) => (

                        <MiniStatsCard
                          iconSrc={item.name === "Trusted institutions" ? book1 : item.name === "Assignment" ? elearning : item.name === "Classes" ? videoPlayer : item.name === "E_Contents" ? groups : onlineClass}
                          title={item.name}
                          quantity={item.count}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} className="spacing-100 classroomWrapper">
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                className="classroomInformationContainer"
              >
                <Reveal effect="fadeInLeft left cascade">
                  <div className="padding-right">
                    <Typography variant="h2" className="About-NEET-Text">
                      {SectionClassroomTitle}
                    </Typography>
                    <Typography className="This-platform-will-text">
                      {SectionClassroomDescription}
                    </Typography>
                    <ul>
                      <li className="view_box_content_text_li">{SectionClassroomFeatures?.[0]}</li>
                      <li className="ulList">
                        {SectionClassroomFeatures?.[1]}
                      </li>
                      <li className="view_box_content_text_li">
                        {SectionClassroomFeatures?.[2]}
                      </li>
                      <li className="view_box_content_text_li">
                        {SectionClassroomFeatures?.[3]}
                      </li>
                    </ul>
                    <Button
                      className="classroomInformation-button"
                      onClick={this.handleOpenZohoFormPopup}
                    >
                      <Typography className="classroom-button-label">
                        Start Learning
                      </Typography>
                    </Button>
                  </div>
                </Reveal>
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="bitmap-img-align">
                <Reveal effect="fadeInRight right cascade">
                  <img
                    src={developerActivity}
                    className="bitmap-img"
                    alt="Bitmap"
                  />
                </Reveal>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <ZohoFormModalPopup
          open={this.state.openZohoForm}
          openThankyouModal={this.handleClickOpen}
          closeThankYou={this.handleClose}
          onCloseModal={this.handleCloseZohoFormPopup}
          isFirstTime={true}
        />
        <ThankYou open={this.state.open} handleClose={this.handleClose} />
      </>
    );
  }
}

