// Customizable Area Start
import React from "react";
import {
  Avatar,
  Box,
  Button,
  Tab,
  Tabs,
  Grid,
  IconButton,
  Typography,
  Hidden,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { call, logoSidePlaced, Header_Call } from "../../assets";
import "../../LandingPage.web.css";
import LandingPageHeaderController, {
  Props,
} from "../LandingPageHeaderController.web";
import AppBarCollapse from "../../../../CustomHeader/src/Header/AppBarCollapse.web";
import { HISTORY } from "../../../../../components/src/common";
import Divider from "@material-ui/core/Divider";
import ZohoFormModalPopup from "../../../../CustomForm/src/ZohoRegistrationFormModalPopup/ZohoFormModalPopup.web";
import useStylesLandingPageHeader from "./styles/LandingPageHeader.styles";
import { PropsWithStyles } from "react-select";
import ThankYou from "../../../../CustomForm/src/ThankYou/ThankYou.web";
// Customizable Area End

class LandingPageHeader extends LandingPageHeaderController {
    // Customizable Area Start
  // Customizable Area End

  render() {
    const { mainTabIndex, handleSelectMainTab, classes } = this.props;

    localStorage.removeItem("mainTabIndex");

    return (
      <Grid container style={{ position: "relative" }}>
        <Grid item xs={12} className={classes.landingPageHeaderContainer}>
          <Box className="width-70per md-width-update">
            <Box
              className="landingPageHeaderBody"
             
            >
              <img alt="Logo" title="Teachze"
                src={logoSidePlaced}
                className="headerlogo"
                id="headerLogoTest"
                onClick={() => handleSelectMainTab("/", 0)}
              />

              <AppBarCollapse
                mainTabIndex={mainTabIndex}
                handleSelectMainTab={handleSelectMainTab}
              />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box className="right-header">
                  <Box className="Talk"
                   
                  >
                    <IconButton
                      color="primary"
                      aria-label="add to shopping cart"
                      //   className="get-in-touch-padding"
                      //   style={{alignItems:"center"}}
                    >
                      <a
                        href="tel:+91 9513317652"
                        className="get-in-touch-item-text"
                        
                      >
                        <img
                          src={Header_Call}
                          alt="call-header" title="Talk"
                          className="call-email-img"
                        ></img>
                      </a>
                    </IconButton>
                    <Hidden smDown>
                      <Box className="get-in-touch-item-text">
                        <p>Talk To Expert</p>
                        <p>
                         <strong>+91 9513317652</strong>
                        </p>
                      </Box>
                    </Hidden>
                  </Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="Free-Trial-Btn"
                    onClick={this.handleOpenZohoFormPopup}
                  >
                    <Box className="Free-Trial-Text">Book Free Trial</Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* <Divider variant="inset" color="#e6edfd" /> */}
        <ZohoFormModalPopup
          open={this.state.openZohoForm}
          openThankyouModal={this.handleClickOpen}
          closeThankYou={this.handleClose}
          onCloseModal={this.handleCloseZohoFormPopup}
          isFirstTime={true}
        />
        <ThankYou open={this.state.open} handleClose={this.handleClose} />
      </Grid>
    );
  }
}

export default withStyles(useStylesLandingPageHeader)(LandingPageHeader);
