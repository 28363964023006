import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { RouteComponentProps } from "react-router-dom";

import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import {
  book,
  school,
  editDocument,
} from "../../../dashboard/src/assets";

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  classes: any;
  navigation: any;
  mainTabIndex: number;
  location: any;
  match: any;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  data: any;
  bookOver: any;
  schoolOver: any;
  documentOver: any;
  bookShadow: any;
  schoolShadow: boolean;
  documentShadow: any;
  selectedIndex: number;
  mainTabIndex: number;
  selectedClass: any;
  content: string;
  blogList: any;
  blogInfo: any;
}

interface SS {
  id: any;
}

export default class BlogController extends BlockComponent<Props, S, SS> {
  getBlogListID: string = "";
  getBlogInfoID: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.get_blog_list = this.get_blog_list.bind(this);
    this.get_blog_info = this.get_blog_info.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      blogInfo: [],
      blogList: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      data: {},
      bookOver: book,
      schoolOver: school,
      documentOver: editDocument,
      bookShadow: false,
      schoolShadow: false,
      documentShadow: false,
      content: "",
      selectedIndex: 0,
      mainTabIndex: 0,
      selectedClass: "Class 6",
    };
    this.userdata = this.userdata.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    console.log("user_data", data);
    this.setState({ data: data });
  };

  handleSelectTab = (tab: string, key: number) => {
    this.setState({
      selectedIndex: key,
    });
  };
  handleSelectMainTab = (tab: string, key: number) => {
    this.setState({
      mainTabIndex: key,
    });
  };
  async componentDidMount() {
    this.get_blog_list();
  }

  get_blog_list = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBlogListID = requestMessage.messageId;
    let apiEndPoint = configJSON.blogEndpoint;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_blog_info = (blogId: any) => {
    
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBlogInfoID = requestMessage.messageId;
    let apiEndPoint = configJSON.blogInfoEndpoint + `?blog_id=${blogId}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getBlogInfoID) {
        debugger
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ blogInfo: responseJson.data.data });
            console.log("blogInfo", this.state.blogInfo);
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      } 

      else if (apiRequestCallId === this.getBlogListID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ blogList: responseJson.data.data });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }  else if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
        let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

        this.showAlert(
          "Change Value",
          "From: " + this.state.txtSavedValue + " To: " + value
        );
        this.setState({ txtSavedValue: value });
      }
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  htmlDecode = (content: any) => {
    let e = document.createElement("div");
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

}
