import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { vectorMask } from "../assets";
import LandingPageHeaderController, {
  Props,
} from "./LandingPageHeaderController.web";
import ZohoFormModalPopup from "../../../CustomForm/src/ZohoRegistrationFormModalPopup/ZohoFormModalPopup.web";
import CustomButton from "../Components/CustomButton";
import Footer from "../Components/Footer/Footer.web";
import ThankYou from "../../../CustomForm/src/ThankYou/ThankYou.web";
import "./LandingPage.style.css";

export default class HomePageFooter extends LandingPageHeaderController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Box>
          <Box
            className="footer-success"
            style={{
              backgroundImage: `url(${vectorMask})`,
            }}
            p={{ xs: "1.5rem", sm: "3rem", md: "5rem" }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={8} md={8}>
                    <Typography
                      className="Start-Learning-with-footer"
                      variant="h2"
                    >
                      Your path to success is just a click away!
                    </Typography>
                    <Typography
                      className="Discover-a-new-way-footer"
                      variant="subtitle2"
                    >
                      Discover a new way of Learning! Welcome to Teachze's
                      unique dynamic and interactive classes within a top-notch
                      educational environment.
                    </Typography>
                  </Grid>
                  <Grid className="flex-item-center" item xs={12} sm={4} md={4}>
                    <CustomButton
                      handleClick={this.handleOpenZohoFormPopup}
                      spanClass={"BOOK-A-FREE-TRIAL-footer"}
                    >
                      BOOK A FREE TRIAL
                    </CustomButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Footer />
        </Box>
        <ZohoFormModalPopup
          open={this.state.openZohoForm}
          openThankyouModal={this.handleClickOpen}
          closeThankYou={this.handleClose}
          onCloseModal={this.handleCloseZohoFormPopup}
          isFirstTime={true}
        />
        <ThankYou open={this.state.open} handleClose={this.handleClose} />
      </React.Fragment>
    );
  }
}
