'use strict';

// const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";

Object.defineProperty(exports, '__esModule', {
  value: true
});
const baseURL = '__MARKER_FOR_BACKEND_URL_REPLACEMENT';

// //dev urls

// const baseURL = 'https://contentlandingsite.b91002.dev.us-east-1.aws.svc.builder.cafe';

// const dashboardURL =
//   'https://dashboard.b91002.dev.us-east-1.aws.svc.builder.cafe';
// const instituteURL =
//   'https://institute-management.b91002.dev.us-east-1.aws.svc.builder.cafe';

// const attendanceURL =
//   'https://attendance-classroom.b91002.dev.us-east-1.aws.svc.builder.cafe';

// const examinationUrl =
//   'https://examination.b91002.dev.us-east-1.aws.svc.builder.cafe';

// const resultUrl =
//   'https://result-grading.b91002.dev.us-east-1.aws.svc.builder.cafe';

//stage urls

//const baseURL = '__MARKER_FOR_BACKEND_URL_REPLACEMENT';

// const dashboardURL =
//   'https://dashboard.b91002.stage.us-east-1.aws.svc.builder.ai';

// const instituteURL =
//   'https://institute-management.b91002.stage.us-east-1.aws.svc.builder.ai';

// const attendanceURL =
//   'https://attendance-classroom.b91002.stage.us-east-1.aws.svc.builder.ai';

// const examinationUrl =
//   'https://examination.b91002.stage.us-east-1.aws.svc.builder.ai';

// const resultUrl = "https://result-grading.b91002.stage.us-east-1.aws.svc.builder.ai"

// https://usermanagement.b91002.dev.us-east-1.aws.svc.builder.cafe

exports.baseURL = baseURL;
// exports.dashboardURL = dashboardURL;
// exports.instituteURL = instituteURL;
// exports.attendanceURL = attendanceURL;
// exports.examinationUrl = examinationUrl;
// exports.resultUrl = resultUrl;
