// Customizable Area Start
import React from "react";
import { Grid, Modal, Typography, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { feedback } from "../assets";
import "./PerformanceReport.web.css";
// Customizable Area End

interface Props {
  // Customizable Area Start
  onCloseModal: any;
  open: any;
  reportList: any;
  enrollNowClick: any;
  // Customizable Area End
}

const PerformanceReport = ({
  onCloseModal,
  open,
  reportList,
  enrollNowClick,
}: Props) => {
  const handleClose = () => {
    onCloseModal();
  };

  return (
    <Modal
      open={!!open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          borderRadius: "20px",
          // height: "80%",
          width: "70%",
          // padding: "3%",
          overflowX: "auto",
          maxHeight: "90%",
        }}
        className="performance_report_modal"
      >
        <div
          style={{ padding: "3% 3% 0 3%" }}
          className="top-spacing-small-screen"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "2%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src={feedback} className="img_feedback_main"></img>
              <Typography className="Performance_Analysis_text">
                {" "}
                Performance Analysis
              </Typography>
            </div>

            <div className="close-rectangle" onClick={handleClose}>
              <Close style={{ color: "#fff", cursor: "pointer" }} />
            </div>
          </div>
        </div>

        <div className="horizonal-line"></div>

        <div style={{ padding: "0 3% 0 3%" }}>
          <Grid container spacing={6} className="fix-spacing">
            {reportList &&
              reportList.map((item: any) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={item.reportImage} className="report_image"></img>
                  <Typography className="report_title">{item.title}</Typography>
                  <Typography className="report_desc">{item.desc}</Typography>
                </Grid>
              ))}
          </Grid>
        </div>

        <div
          style={{ padding: "0 3% 3% 3%" }}
          className="top-spacing-small-screen bottom-spacing-small-screen"
        >
          <Grid container>
            <Grid item xs={12} className="center-content">
              <Button className="Enroll-Now-button" onClick={enrollNowClick}>
                <Typography className="Enroll-Now-btn-text">
                  Enroll Now
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};
export default PerformanceReport;
