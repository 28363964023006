import React from "react";
import { Typography, Grid } from "@material-ui/core";
import PrivacyPolicyController from "./PrivacyPolicyController.web";
import { policyMainImage } from "../../../dashboard/src/assets";
import "./PrivacyPolicy.web.css";
import lottieJson from "../../privacyPolicy.json";
import Lottie from "react-lottie-player";

const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;
const Reveal = require("react-reveal/Reveal");

class PrivacyPolicy extends PrivacyPolicyController {


  render() {
    return (
      <div style={root}>
        <Grid container style={{ position: "relative" }}>
          <Grid item xs={12} className="policy-bg-mask">
            <Grid container>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "15%",
                }}
                className="small-screen-centered"
              >
                <Reveal effect="fadeInLeft left cascade">
                  <Typography className="policy_textlabel_heading">
                    Policy & Governance
                  </Typography>
                  <Typography className="Last-modified-text">
                    Last modified: February 15, 2021
                  </Typography>
                </Reveal>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                style={{ display: "flex", justifyContent: "flex-end" }}
                className="small-screen-justify-center"
              >
                <Reveal effect="fadeInRight right cascade">
                  <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    className="policy-image"
                  />
                  {/* <img src={policyMainImage} className="policy-image"></img> */}
                </Reveal>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              background: "#f8f9ff",
            }}
          >
            <div className="width-70per">
              <Grid container style={{ marginTop: "-6.5%" }}>
                <Grid item xs={12}>
                  <Reveal effect="fadeInUp">
                    <div className="privacy_policy_view_box">
                      <Typography className="view_box_header_text">
                        Overview
                      </Typography>
                      <Typography className="view_box_content_text">
                        Teachze (Learningbot Education Private Limited) (the
                        “Teachze” or “we” or “our” or “us”) shall have the
                        meaning as defined under the ‘Terms of Use’ available
                        at. Teachze considers the privacy and protection of its
                        user’s personal data to be of high importance. It is
                        clarified that this privacy policy (the “Privacy
                        Policy”) shall form an integral part of the Terms of Use
                        of the Website and should be read in conjunction with
                        such Terms of Use. Illegality or unenforceability of one
                        or more Terms of Use shall not affect the legality and
                        enforceability of the other terms of use of the website
                        www.teachze.com (the “Website”)
                      </Typography>
                      <Typography className="view_box_content_text_2">
                        Our practices and procedures in relation to the
                        collection and use of personal data have been set-out
                        below in order to ensure the safe usage of the Website
                        for you. We have implemented reasonable security
                        practices and procedures that are commensurate with the
                        information assets being protected and with the nature
                        of our activities. While we try our best to provide
                        security that is better than industry standards, because
                        of the inherent vulnerabilities of the internet, we
                        cannot ensure or warrant complete security of all
                        information that is being transmitted to us by you. This
                        Privacy Policy is subject to change at any time without
                        notice. To make sure you are aware of any changes,
                        please review this Privacy Policy periodically. By
                        visiting the Website, you agree to be bound by the terms
                        and conditions of this Privacy Policy.
                      </Typography>
                      <Typography className="view_box_content_text_2">
                        This Privacy Policy is published pursuant to:
                      </Typography>
                      <ul>
                        <li className="view_box_content_text">
                          Section 43A of the Information Technology Act, 2000;
                        </li>
                        <li className="view_box_content_text">
                          Regulation 4 of the Information Technology (Reasonable
                          Security Practices and Procedures and Sensitive
                          Personal Information) Rules, 2011;
                        </li>
                        <li className="view_box_content_text">
                          Regulation 3(1) of the Information Technology
                          (Intermediaries Guidelines) Rules, 2011; and
                        </li>
                        <li className="view_box_content_text">
                          Other applicable laws in India.
                        </li>
                      </ul>
                      <Typography className="view_box_content_text_2">
                        In this Privacy Policy, we will disclose what kind of
                        information is collected from the users. It will also
                        specify why we collect this information, the modes and
                        means by which it will be used, and the entities or
                        persons who will have access to this information.
                      </Typography>
                    </div>
                  </Reveal>

                  <Reveal effect="fadeInUp">
                    <div className="privacy_policy_view_box">
                      <Typography className="view_box_header_text">
                        Information Collection
                      </Typography>
                      <Typography className="view_box_content_text">
                        Teachze through our Website collects contact
                        information, Academic details, KYC details, Billing
                        information, information through survey, information
                        through interaction and other personally identifying
                        information ("Personal Data") only for the purposes as
                        mentioned in the Terms of Use. In general, you may
                        browse our Website anonymously. However, some of our
                        services require you to register on the Website and may
                        require the collection of some personally identifying
                        information, such as your name, address, contact number,
                        email id, etc. You may contact our representative at
                        +9197411 33636 in order to amend or rectify any personal
                        information. You may also intimate us if you do not wish
                        that we retain or use your personal information.
                        However, in such a case, we may not be able to provide
                        you some of our services and/or access to our Website.
                      </Typography>
                    </div>
                  </Reveal>

                  <Reveal effect="fadeInUp">
                    <div className="privacy_policy_view_box">
                      <Typography className="view_box_header_text">
                        Purpose of Information Collection
                      </Typography>
                      <Typography className="view_box_content_text">
                        We will collect, record, store, handle and use your
                        Personal Data only for the purpose of enabling you to
                        effectively use the Website and to advise you of our
                        other services and related details. We use your Personal
                        Data to improve teaching, resolve disputes; help promote
                        a safe service; calibrate user and public interest in
                        our products and services; troubleshoot problems;
                        customize user experience; detect and protect us against
                        error, fraud and other criminal activity; inform you
                        about relevant updates and other social causes; enforce
                        our terms and conditions; and as otherwise described to
                        you at the time of collection of the data.
                      </Typography>
                    </div>
                  </Reveal>

                  <Reveal effect="fadeInUp">
                    <div className="privacy_policy_view_box">
                      <Typography className="view_box_header_text">
                        Privacy, Security Policy and Related Aspects
                      </Typography>
                      <Typography className="view_box_content_text">
                        We do not sell or rent your Personal Data to third
                        parties for their marketing purposes without your
                        explicit consent and we only use your information as
                        described in the Terms of Use. Your Personal Data is
                        protected by reasonable, physical as well as
                        technological security devices. We may, however,
                        disclose and / or transfer your information to our
                        affiliates, agents, service providers, etc. for business
                        purposes and to provide you a better experience.
                        Notwithstanding anything to the contrary contained
                        anywhere in the Terms of Use and to the greatest extent
                        permitted by applicable law Teachze will not be
                        responsible in any manner whatsoever if information
                        provided by users gets exposed and/or misused by any
                        third party who accessed such information without any
                        authorization from Teachze.
                      </Typography>
                      <Typography className="view_box_content_text_2">
                        Teachze captures certain information including time,
                        date, IP address, and other information that will be
                        used to locate and identify individuals for combating
                        fraud. If any information is suspected to be fraudulent,
                        all records will be submitted, with or without a notice
                        / subpoena, to law enforcement agencies for fraud
                        investigation, as may be deemed appropriate by Teachze
                        and Teachze will endeavour to cooperate with authorities
                        to prosecute offenders to the full extent of the law.
                      </Typography>
                      <Typography className="view_box_content_text_2">
                        Teachze owns all the intellectual property rights
                        associated with the Website and its contents. No right,
                        title or interest in any downloaded material is
                        transferred to you as a result of any such downloading
                        or copying except as specifically allowed by the Website
                        itself. The Website is protected by copyright as a
                        collective work and/or compilation (meaning the
                        collection, arrangement, and assembly) of all the
                        content on this Website, pursuant to applicable law.
                      </Typography>
                      <Typography className="view_box_content_text_2">
                        Certain elements of the Website will contain material
                        submitted by other users and some parts of the Website
                        may contain advertising/other material submitted to
                        Teachze by third parties. Teachze reserves the right to
                        omit, suspend and/or change the position of any
                        advertising material submitted for insertion. Acceptance
                        of advertisements on the Website will be subject to
                        Teachze’s terms and conditions which are available on
                        request.
                      </Typography>
                      <Typography className="view_box_content_text_2">
                        Teachze’s logos, product and service marks and/ or
                        names, trademarks, patents, copyrights and other
                        intellectual property, whether registered or not
                        (“Teachze IP”) are exclusively owned by Teachze. Without
                        the prior written permission of Teachze, the User agrees
                        to not display and/or use Teachze IP in any manner.
                        Nothing contained in this Website or the content, should
                        be construed as granting, in any way, any license or
                        right or interest whatsoever, in and/ or to Teachze IP,
                        without the express written permission of Teachze.
                      </Typography>
                      <Typography className="view_box_content_text_2">
                        References on this Website to any names, marks, products
                        or services of third parties or hypertext links to third
                        party websites or information are provided solely for
                        your convenience to you and do not in any way constitute
                        or imply our endorsement, sponsorship or recommendation
                        of the third party, information, product or service.
                      </Typography>
                    </div>
                  </Reveal>

                  <Reveal effect="fadeInUp">
                    <div className="privacy_policy_view_box">
                      <Typography className="view_box_header_text">
                        Acceptable Use
                      </Typography>
                      <Typography className="view_box_content_text">
                        You are prohibited from violating or attempting to
                        violate the security of the Website, including, without
                        limitation:
                      </Typography>
                      <ul>
                        <li className="view_box_content_text">
                          Attempting to probe, scan or test the vulnerability of
                          a system or network or to breach security or
                          authentication measures without proper authorization;
                        </li>
                        <li className="view_box_content_text">
                          Attempting to interfere with service to any other
                          user, host or network, including, without limitation,
                          via means of submitting a virus to the Website,
                          overloading, 'flooding,' 'spamming', 'mail bombing' or
                          'crashing';
                        </li>
                        <li className="view_box_content_text">
                          Sending unsolicited email, including promotions and/or
                          advertising of products or services; or
                        </li>
                        <li className="view_box_content_text">
                          Forging any TCP/IP packet header or any part of the
                          header information in any email or newsgroup posting.
                        </li>
                      </ul>
                      <Typography className="view_box_content_text_2">
                        Violations of system or network security may result in
                        civil and / or criminal liability. We will investigate
                        occurrences that may involve such violations and may
                        involve, and cooperate with, law enforcement authorities
                        in prosecuting users who are involved in such
                        violations. You agree not to use any device, software or
                        routine to interfere or attempt to interfere with the
                        proper working of this Website or any activity being
                        conducted on this Website. You agree, further, not to
                        use or attempt to use any engine, software, tool, agent
                        or other device or mechanism (including without
                        limitation browsers, spiders, robots, or intelligent
                        agents) to navigate or search this Website other than
                        the search engine and search agents available on this
                        Website and other than generally available third party
                        web browsers.
                      </Typography>
                    </div>
                  </Reveal>

                  <Reveal effect="fadeInUp">
                    <div className="privacy_policy_view_box">
                      <Typography className="view_box_header_text">
                        Email
                      </Typography>
                      <Typography className="view_box_content_text">
                        [In order to provide you with timely notice, updates and
                        other information, we may send you emails.] To
                        facilitate this service, we may note some of the pages
                        you visit on our Website. At any time, you may
                        discontinue this service by using the link provided in
                        the mailers itself or sending an email
                        to hello@teachze.com, requesting that your email address
                        be removed from our lists.
                      </Typography>
                    </div>
                  </Reveal>

                  <Reveal effect="fadeInUp">
                    <div className="privacy_policy_view_box">
                      <Typography className="view_box_header_text">
                        Cookies
                      </Typography>
                      <Typography className="view_box_content_text">
                        Cookies are tiny text files which identify your computer
                        to server as a unique user when you visit certain pages
                        on the Website and they are stored by your internet
                        browser on your computer's hard drive. Cookies can be
                        used to recognize your Internet Protocol address, saving
                        you time while you are on, or want to enter, the Website
                        and not for obtaining or using any other information
                        about you. The acceptance of cookies is not a
                        requirement for visiting the Website. However, we would
                        like to point out that some functionality on the Website
                        is only possible with the activation of cookies.
                      </Typography>
                    </div>
                  </Reveal>

                  <Reveal effect="fadeInUp">
                    <div className="privacy_policy_view_box">
                      <Typography className="view_box_header_text">
                        Exceptions
                      </Typography>
                      <Typography className="view_box_content_text">
                        While we will not voluntarily disclose your personal
                        data apart from the manner set out in the Terms of Use,
                        we may disclose such information if we are required to
                        do so by a court order, if we are requested to do so by
                        government or law enforcement authorities, if we are
                        required to do so pursuant to other legal processes, or
                        if it becomes necessary to protect the rights or
                        property of Teachze.
                      </Typography>
                    </div>
                  </Reveal>

                  <Reveal effect="fadeInUp">
                    <div className="privacy_policy_view_box">
                      <Typography className="view_box_header_text">
                        Opt-Out Policy
                      </Typography>
                      <Typography className="view_box_content_text">
                        All users may opt-out of receiving non-essential,
                        promotional, or marketing-related communication from
                        Teachze. These settings can be found on the Website. If
                        a user wishes to remove his/her/its contact information
                        from Teachze’s lists and newsletters, the user can
                        contact Teachze at hello@teachze.com. Teachze however
                        reserves the right to limit accessibility to the Website
                        and the services provided by it based on availability of
                        information from the users.
                      </Typography>
                    </div>
                  </Reveal>

                  <Reveal effect="fadeInUp">
                    <div className="privacy_policy_view_box">
                      <Typography className="view_box_header_text">
                        Grievance Officer
                      </Typography>
                      <Typography className="view_box_content_text">
                        Users can access, modify, correct and eliminate the
                        Personal Data which has been collected pursuant to
                        registration on the Website. Any grievances in relation
                        to the Personal Data shared by the user with Teachze may
                        be brought to the attention of the Grievance Officer
                        Santosh Kumar AV, at 148, block II, Embassy Square,
                        Infantry Road, Bangalore 560001, +9197411 33636,
                        hello@teachze.com
                      </Typography>
                      <Typography className="view_box_content_text_2">
                        If you have questions or comments please send them to us
                        or contact us at contact details mentioned in this
                        Privacy Policy.
                      </Typography>
                    </div>
                  </Reveal>

                  <Reveal effect="fadeInUp">
                    <div
                      className="privacy_policy_view_box"
                      style={{ marginBottom: "10%" }}
                    >
                      <Typography className="view_box_header_text">
                        Contact
                      </Typography>
                      <Typography className="view_box_content_text">
                        If you have any queries in relation to the use and
                        protection of your Personal Data or otherwise in
                        relation to the Website, please contact us at
                        hello@teachze.com
                      </Typography>
                    </div>
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default PrivacyPolicy;
