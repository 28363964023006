import React from "react";
import LandingPageController from "../../../dashboard/src/LandingPage/LandingPageController.web";
import { Box } from "@material-ui/core";
import LandingPageHeader from "../../../dashboard/src/LandingPage/LandingPageHeader/LandingPageHeader.web";
import LandingPageFooter from "./BlogPageFooter.web";

import BlogPageDetail from "./Blogdetail.web";

const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;

export default class BlogDetailMainPage extends LandingPageController {
  render() {
    const { mainTabIndex } = this.state;
    return (
      <div style={root}>
        <LandingPageHeader
          mainTabIndex={4}
          handleSelectMainTab={this.handleSelectMainTab}
        />
        <Box>
          <BlogPageDetail
            classes={undefined}
            navigation={undefined}
            mainTabIndex={mainTabIndex}
          />
        </Box>
        <LandingPageFooter mainTabIndex={0} handleSelectMainTab={undefined} />
      </div>
    );
  }
}
