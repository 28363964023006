import React from "react";
import {
    Typography,
    Grid,
    Hidden,
} from "@material-ui/core";
import TechnologyController from './TechnologyController.web'
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import { thumbnail, videoPlayIcon, onlineLearningPana, webinarPana, groupVideoPana, webinarBro, telecommuting, discussion } from '../../../dashboard/src/assets'
import './Technology.web.css'

const root = {
    flexGrow: 1,
    overflowX: "hidden",
    position: "relative"
} as const;
const Reveal = require("react-reveal/Reveal");

class Technology extends TechnologyController {
    
    render() {
        const TechnologyTitle = this.state.technologyList[1]?.attributes?.section_items?.data[0]?.attributes?.title
        const TechnologyDescription = this.state.technologyList[1]?.attributes?.section_items?.data[0]?.attributes?.description
        const TechnologyDescriptionTwo = this.state.technologyList[2]?.attributes?.section_items?.data[0]?.attributes?.title
        const TechnologySectionTitleOne = this.state.technologyList[3]?.attributes?.section_items?.data[0]?.attributes?.title
        const TechnologySectionDescriptionOne= this.state.technologyList[3]?.attributes?.section_items?.data[0]?.attributes?.description
        const TechnologySectionTitleTwo = this.state.technologyList[4]?.attributes?.section_items?.data[0]?.attributes?.title
        const TechnologySectionDescriptionTwo= this.state.technologyList[4]?.attributes?.section_items?.data[0]?.attributes?.description
        const TechnologySectionTitleThree = this.state.technologyList[5]?.attributes?.section_items?.data[0]?.attributes?.title
        const TechnologySectionDescriptionThree= this.state.technologyList[5]?.attributes?.section_items?.data[0]?.attributes?.description
        const TechnologySectionTitleFour = this.state.technologyList[6]?.attributes?.section_items?.data[0]?.attributes?.title
        const TechnologySectionDescriptionFour= this.state.technologyList[6]?.attributes?.section_items?.data[0]?.attributes?.description
        const TechnologySectionTitleFive= this.state.technologyList[7]?.attributes?.section_items?.data[0]?.attributes?.title
        const TechnologySectionDescriptionFive= this.state.technologyList[7]?.attributes?.section_items?.data[0]?.attributes?.description
        const TechnologySectionTitleSix= this.state.technologyList[8]?.attributes?.section_items?.data[0]?.attributes?.list_items?.data
        const TechnologySectionDescriptionSix= this.state.technologyList[8]?.attributes?.section_items?.data[0]?.attributes?.list_items?.data
      
        return (
            <div style={root}>
                <Helmet>
          <title>Teachze | Empowering students with the latest technologies</title>
          <meta name="description" content="Join Teachze LIVE online tuitions and Online coaching classes to experience its unique dynamic and interactive classes within a top-notch educational environment. Teachze empowers each student with giving access to the unlimited power of the latest technologies. With Teachze, anyone can learn anything from anywhere at any time. " />
        </Helmet>
                <Grid container style={{ position: "relative" }}>
                    <Grid className="Competitive-container-info" item xs={12} >
                        <div className="width-70per">
                            <Grid container style={{ padding: "2% 0 0 0" }}>
                                <Grid item xs={12} sm={7} md={7} className="technology-main-block-style" >
                                  <Reveal effect="fadeInLeft left cascade">
                                   <Typography variant="h1" className="Empowering-Students-text">
                                        {TechnologyTitle}
                                    </Typography>
                                    <Typography className="Own-the-unlimited-text">
                                    {TechnologyDescription}
                                    </Typography>
                                   </Reveal>
                                </Grid>
                                <Grid className="" item xs={12} sm={5} md={5} style={{ marginBottom: "-2%" }}>
                                    <Reveal effect="fadeInRight right cascade">
                                        <div style={{ width: "100%" }}>
                                            <ReactPlayer
                                                url="https://player.vimeo.com/progressive_redirect/playback/670524444/rendition/540p/540p.mp4?loc=external&oauth2_token_id=1401671315&signature=6345b05d131e84b0af96a1d0bf2aa51b1ffd4dd1ac1bac44748069b7fd3acbfd"
                                                className="react-player video-display-size"
                                                style={{ borderRadius: "8px" }}
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            controlsList: "nodownload",
                                                        },
                                                    },
                                                }}
                                                playing
                                                width="100%"
                                                height="310px"
                                                light={thumbnail}
                                                controls
                                                playIcon={<img alt="play icon" src={videoPlayIcon} />}
                                            />
                                        </div>
                                    </Reveal>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid className="justifyContent-center" item xs={12}>
                        <div className="width-70per">
                            <Grid className="main-block-box" container>
                                <Reveal effect="fadeInUp">
                                <div className="main-block-box">
                                    <Typography variant="h3" className="TECHNOLOGY-text">
                                        TECHNOLOGY
                                    </Typography>
                                    <Typography variant="h2" className="Our-Learning-Manage-text">
                                        {TechnologyDescriptionTwo}
                                    </Typography>
                                </div>
                                </Reveal>
                                <Grid item xs={12}>
                                    <Grid container className="padding-top-bottom">
                                        <Grid item xs={12} sm={6} className="left-block">
                                            <Reveal effect="fadeInLeft left cascade">
                                            <img title="Distance learning" alt="online learning" src={onlineLearningPana} className="block-img"></img>
                                            </Reveal>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="right-block">
                                            <Reveal effect="fadeInRight right cascade">
                                            <Typography variant="h2" className="block-text-1">
                                                {TechnologySectionTitleOne}
                                            </Typography>
                                            <Typography className="block-text-2">
                                                {TechnologySectionDescriptionOne}
                                            </Typography>
                                            </Reveal>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="padding-top-bottom">
                                        <Hidden only={['xs']}>
                                            <Grid item xs={12} sm={6} className="left-block">
                                                <Reveal effect="fadeInLeft left cascade">
                                                <Typography variant="h2" className="block-text-1">
                                                    {TechnologySectionTitleTwo}
                                                </Typography>
                                                    <Typography className="block-text-2">
                                                        {TechnologySectionDescriptionTwo}
                                                </Typography>
                                                </Reveal>
                                            </Grid>
                                        </Hidden>
                                      
                                        <Grid item xs={12} sm={6} className="right-block">
                                            <Reveal effect="fadeInRight right cascade">
                                            <img title="Live and Interactive Classes" alt="Live Classes" src={webinarPana} className="block-img"></img>
                                            </Reveal>
                                            
                                        </Grid>

                                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                            <Grid item xs={12} sm={6} className="left-block">
                                                <Reveal effect="fadeInLeft left cascade">
                                                <Typography variant="h2" className="block-text-1">
                                                    {TechnologySectionTitleTwo}
                                                </Typography>
                                                    <Typography className="block-text-2">
                                                        {TechnologySectionDescriptionTwo}
                                                </Typography>
                                                </Reveal>
                                            </Grid>
                                        </Hidden>
                                    </Grid>

                                    <Grid container className="padding-top-bottom">
                                        <Grid item xs={12} sm={6} className="left-block">
                                            <Reveal effect="fadeInLeft left cascade">
                                            <img title="Video Library" alt="group video" src={groupVideoPana} className="block-img"></img>
                                            </Reveal>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="right-block">
                                            <Reveal effect="fadeInRight right cascade">
                                            <Typography variant="h2" className="block-text-1">
                                            {TechnologySectionTitleThree}
                                            </Typography>
                                                <Typography className="block-text-2">
                                                {TechnologySectionDescriptionThree}
                                            </Typography>
                                            </Reveal>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="padding-top-bottom">
                                        <Hidden only={['xs']}>
                                            <Grid item xs={12} sm={6} className="left-block">
                                                <Reveal effect="fadeInLeft left cascade">
                                                <Typography variant="h2" className="block-text-1">
                                                    {TechnologySectionTitleFour}
                                                </Typography>
                                                    <Typography className="block-text-2">
                                                        {TechnologySectionDescriptionFour}
                                                </Typography>
                                                </Reveal>
                                            </Grid>
                                        </Hidden>
                                        <Grid item xs={12} sm={6} className="right-block">
                                            <Reveal effect="fadeInRight right cascade">
                                            <img title="Online Sessions and Assessment" alt="Online sessions" src={webinarBro} className="block-img"></img>
                                            </Reveal>
                                        </Grid>
                                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                            <Grid item xs={12} sm={6} className="left-block">
                                                <Reveal effect="fadeInLeft left cascade">
                                                <Typography variant="h2" className="block-text-1">
                                                {TechnologySectionTitleFour}
                                                </Typography>
                                                    <Typography className="block-text-2">
                                                    {TechnologySectionDescriptionFour}
                                                </Typography>
                                                </Reveal>
                                            </Grid>
                                        </Hidden>
                                    </Grid>

                                    <Grid container className="padding-top-bottom">
                                        <Grid item xs={12} sm={6} className="left-block">
                                            <Reveal effect="fadeInLeft left cascade">
                                            <img title="Online Library" alt="telecommuting" src={telecommuting} className="block-img"></img>
                                            </Reveal>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="right-block">
                                            <Reveal effect="fadeInRight right cascade">
                                            <Typography variant="h2" className="block-text-1">
                                               {TechnologySectionTitleFive}
                                            </Typography>
                                                <Typography className="block-text-2">
                                                    {TechnologySectionDescriptionFive}
                                            </Typography>
                                            </Reveal>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="padding-top-bottom">
                                        <Hidden only={['xs']}>
                                            <Grid item xs={12} sm={6} className="left-block">
                                                <Reveal effect="fadeInLeft left cascade">
                                                    {TechnologySectionTitleSix?.map((item: any) => (
                                                        
                                                <div className="sub-block-rectangle">
                                                    <Typography variant="h4" className="sub-block-header-text">
                                                    {item.attributes.title}
                                                    </Typography>
                                                            <Typography className="sub-block-content-text ">
                                                                {item.attributes.description}
                                                    </Typography>
                                                </div>
                                                    ))}

                                               
                                            </Reveal>
                                            </Grid>
                                        </Hidden>
                                        <Grid item xs={12} sm={6} className="right-block">
                                            <Reveal effect="fadeInRight right cascade">
                                            <img title="Discussion Forum" alt="understanding discussion" src={discussion} className="block-img"></img>
                                            </Reveal>
                                        </Grid>

                                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                            <Grid item xs={12} sm={6} className="left-block">
                                                <Reveal effect="fadeInLeft left cascade">
                                        
                                                {TechnologySectionTitleSix?.map((item: any) => (
                                                        
                                                        <div className="sub-block-rectangle">
                                                            <Typography variant="h4" className="sub-block-header-text">
                                                            {item.attributes.title}
                                                            </Typography>
                                                        <Typography className="sub-block-content-text ">
                                                        {item.attributes.description}
                                                            </Typography>
                                                        </div>
                                                            ))}
                                                </Reveal>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Technology;