import React from "react";
import { Menu, Popper, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Hidden, MenuItem } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { image_dropdown_arrow, image_dropdown_arrow_blue } from "../assets";
import { HISTORY } from "../../../../components/src/common";

interface S {
  anchorEl: any;
  anchorElCompExam: any;
  openCompExamMenu: any;
  anchorElTution: any;
  openTutionMenu: any;
  anchorElTestSeries: any;
  openTestSeriesMenu: any;
  anchorElMore: any;
  openMoreMenu: any;
}
interface Props {
  anchorEl: any;
  handleSelectMainTab: any;
  mainTabIndex: any;
}
class MenuAppBarCollapse extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: this.props.anchorEl,
      anchorElCompExam: null,
      openCompExamMenu: false,
      anchorElTution: null,
      openTutionMenu: false,
      anchorElTestSeries: null,
      openTestSeriesMenu: false,
      anchorElMore: null,
      openMoreMenu: false,
    };
  }

  handleMenu = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
      anchorElCompExam: null,
      openCompExamMenu: false,
      openTutionMenu: false,
      openTestSeriesMenu: false,
      openMoreMenu: false,
    });
  };
  render() {
    // const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const handleClickCompExam = (event: any) => {
      if (this.state.openCompExamMenu) {
        handleCloseCompExamMenu();
      } else {
        this.setState({
          anchorElCompExam: event.currentTarget,
          openCompExamMenu: true,
        });
      }
    };

    const handleCloseCompExamMenu = () => {
      this.setState({ anchorElCompExam: null, openCompExamMenu: false });
    };

    const handleClickTutionMenu = (event: any) => {
      if (this.state.openTutionMenu) {
        handleCloseTutionMenu();
      } else {
        this.setState({
          anchorElTution: event.currentTarget,
          openTutionMenu: true,
        });
      }
    };

    const handleCloseTutionMenu = () => {
      this.setState({ anchorElTution: null, openTutionMenu: false });
    };

    const handleClickTestSeriesMenu = (event: any) => {
      if (this.state.openTestSeriesMenu) {
        handleCloseTestSeriesMenu();
      } else {
        this.setState({
          anchorElTestSeries: event.currentTarget,
          openTestSeriesMenu: true,
        });
      }
    };

    const handleCloseTestSeriesMenu = () => {
      this.setState({ anchorElTestSeries: null, openTestSeriesMenu: false });
    };

    const handleClickMore = (event: any) => {
      if (this.state.openMoreMenu) {
        handleCloseMoreMenu();
      } else {
        this.setState({
          anchorElMore: event.currentTarget,
          openMoreMenu: true,
        });
      }
    };

    const handleCloseMoreMenu = () => {
      this.setState({ anchorElMore: null, openMoreMenu: false });
    };

    const moveToCompetitiveExam = (path: any) => {
      this.setState({ anchorEl: null });
      HISTORY.push(path);
    };

    const moveToTution = (path: any) => {
      this.setState({ anchorEl: null });
      HISTORY.push(path);
    };

    const moveToTestSeries = (path: any) => {
      this.setState({ anchorEl: null });
      HISTORY.push(path);
    };

    return (
      <div>
        <Hidden mdUp>
          <IconButton
            onClick={this.handleMenu}
            className="top-spacing"
            style={{
              position: "absolute",
              left: 0,
              top: 20,
              marginLeft: "-25px",
            }}
          >
            <MenuIcon style={{ height: 30, width: 30 }} />
          </IconButton>
        </Hidden>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={this.handleClose}
          style={{ zIndex: 1 }}
        >
          <MenuItem
            style={{
              borderBottom:
                this.props.mainTabIndex === 0 ? "1px solid  #5580ea" : "",
              margin: "15px 10px",
              fontFamily: "Inter",
              color: this.props.mainTabIndex === 0 ? "#5580ea" : "#394052",
            }}
            onClick={() => {
              this.setState({ anchorEl: null });
              this.props.handleSelectMainTab("/", 0);
            }}
          >
            Home
          </MenuItem>
          <MenuItem
            style={{
              borderBottom:
                this.props.mainTabIndex === 1 ? "1px solid #5580ea" : "",
              margin: "15px 10px",
              fontFamily: "Inter",
              color: this.props.mainTabIndex === 1 ? "#5580ea" : "#394052",
            }}
          >
            {" "}
            <span
              onClick={() => {
                this.setState({ anchorEl: null });
                handleCloseCompExamMenu();
                HISTORY.push("/Competitive-exams");
              }}
            >
              Competitive Exam
            </span>
            <img
              src={
                this.props.mainTabIndex === 1
                  ? image_dropdown_arrow_blue
                  : image_dropdown_arrow
              }
              onClick={handleClickCompExam}
              className="more_dropdown_arrow"
            />
          </MenuItem>
          <MenuItem
            style={{
              borderBottom:
                this.props.mainTabIndex === 2 ? "1px solid #5580ea" : "",
              margin: "15px 10px",
              fontFamily: "Inter",
              color: this.props.mainTabIndex === 2 ? "#5580ea" : "#394052",
            }}
          >
            <span
              onClick={() => {
                this.setState({ anchorEl: null });
                handleCloseTutionMenu();
                HISTORY.push("/Tuitions");
              }}
            >
              Tuitions
            </span>
            <img
              src={
                this.props.mainTabIndex === 2
                  ? image_dropdown_arrow_blue
                  : image_dropdown_arrow
              }
              onClick={handleClickTutionMenu}
              className="more_dropdown_arrow"
            />
          </MenuItem>
          <MenuItem
            style={{
              borderBottom:
                this.props.mainTabIndex === 3 ? "1px solid #5580ea" : "",
              margin: "15px 10px",
              fontFamily: "Inter",
              color: this.props.mainTabIndex === 3 ? "#5580ea" : "#394052",
            }}
          >
            <span
              onClick={() => {
                this.setState({ anchorEl: null });
                handleCloseTestSeriesMenu();
                HISTORY.push("/Test-series");
              }}
            >
              Test Series
            </span>
            <img
              src={
                this.props.mainTabIndex === 3
                  ? image_dropdown_arrow_blue
                  : image_dropdown_arrow
              }
              onClick={handleClickTestSeriesMenu}
              className="more_dropdown_arrow"
            />
          </MenuItem>
          <MenuItem
            style={{
              borderBottom:
                this.props.mainTabIndex === 4 ? "1px solid #5580ea" : "",
              margin: "15px 10px",
              fontFamily: "Inter",
              color: this.props.mainTabIndex === 4 ? "#5580ea" : "#394052",
            }}
          >
            {" "}
            <span onClick={handleClickMore}>More</span>
            <img
              src={
                this.props.mainTabIndex === 4
                  ? image_dropdown_arrow_blue
                  : image_dropdown_arrow
              }
              onClick={handleClickMore}
              className="more_dropdown_arrow"
            />
          </MenuItem>
        </Menu>

        <Popper
          anchorEl={this.state.anchorElCompExam}
          keepMounted
          open={this.state.openCompExamMenu}
          className="more-item-list"
          style={{ zIndex: 1000 }}
        >
          <Paper>
            <MenuItem
              className="more-list-item"
              onClick={(e) => {
                handleCloseCompExamMenu();
                moveToCompetitiveExam("/Competitive-exams/Neet");
              }}
            >
              NEET
            </MenuItem>
            <MenuItem
              className="more-list-item"
              onClick={(e) => {
                handleCloseCompExamMenu();
                moveToCompetitiveExam("/Competitive-exams/Jee");
              }}
            >
              JEE
            </MenuItem>
            <MenuItem
              className="more-list-item"
              onClick={(e) => {
                handleCloseCompExamMenu();
                moveToCompetitiveExam("/Competitive-exams/CA");
              }}
            >
              CA
            </MenuItem>
            <MenuItem
              className="more-list-item"
              onClick={(e) => {
                handleCloseCompExamMenu();
                moveToCompetitiveExam("/Competitive-exams/Foundation");
              }}
            >
              Foundation
            </MenuItem>
          </Paper>
        </Popper>

        <Popper
          anchorEl={this.state.anchorElTution}
          keepMounted
          open={this.state.openTutionMenu}
          className="more-item-list"
          style={{ zIndex: 1000 }}
        >
          <Paper>
            <MenuItem
              className="more-list-item"
              onClick={(e) => {
                handleCloseTutionMenu();
                moveToTution("/Tuitions/CBSE");
              }}
            >
              CBSE
            </MenuItem>
            <MenuItem
              className="more-list-item"
              onClick={(e) => {
                handleCloseTutionMenu();
                moveToTution("/Tuitions/TN-Board");
              }}
            >
              TN Board
            </MenuItem>
            {/* <MenuItem className="more-list-item" onClick={(e) => {
              handleCloseTutionMenu()
              moveToTution("/Tuitions/Foundation")
            }}>Foundation Courses</MenuItem> */}
          </Paper>
        </Popper>

        <Popper
          anchorEl={this.state.anchorElTestSeries}
          keepMounted
          open={this.state.openTestSeriesMenu}
          className="more-item-list"
          style={{ zIndex: 1000 }}
        >
          <Paper>
            <MenuItem
              className="more-list-item"
              onClick={(e) => {
                handleCloseTestSeriesMenu();
                moveToTestSeries("/Test-series/Neet");
              }}
            >
              NEET
            </MenuItem>
            <MenuItem
              className="more-list-item"
              onClick={(e) => {
                handleCloseTestSeriesMenu();
                moveToTestSeries("/Test-series/Jee");
              }}
            >
              JEE
            </MenuItem>
          </Paper>
        </Popper>

        <Popper
          anchorEl={this.state.anchorElMore}
          keepMounted
          open={this.state.openMoreMenu}
          className="more-item-list"
          style={{ zIndex: 1000 }}
        >
          <Paper>
            <MenuItem
              className="more-list-item"
              onClick={(e) => {
                handleCloseMoreMenu();
                HISTORY.push("/AboutUs");
              }}
            >
              About Us
            </MenuItem>
            <MenuItem
              className="more-list-item"
              onClick={(e) => {
                handleCloseMoreMenu();
                HISTORY.push("/Faculties");
              }}
            >
              Our Team
            </MenuItem>
            <MenuItem
              className="more-list-item"
              onClick={(e) => {
                handleCloseMoreMenu();
                HISTORY.push("/Technology");
              }}
            >
              Technology
            </MenuItem>
            <MenuItem
              className="more-list-item"
              onClick={(e) => {
                handleCloseMoreMenu();
                HISTORY.push("/Blogs");
              }}
            >
              Blogs
            </MenuItem>
          </Paper>
        </Popper>
      </div>
    );
  }
}
export default MenuAppBarCollapse;
