import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { logoSidePlaced } from "../assets";
import "./CourseDetails.web.css";
import LandingPageHeaderController, {
  Props,
} from "../LandingPage/LandingPageHeaderController.web";
import CourseDetailsController from "./CourseDetailsController.web";
import { HISTORY } from "../../../../components/src/common";
import ZohoFormModalPopup from "../../../CustomForm/src/ZohoRegistrationFormModalPopup/ZohoFormModalPopup.web";
import ThankYou from "../../../CustomForm/src/ThankYou/ThankYou.web";

export default class LandingPageHeader extends CourseDetailsController {
  render() {
    // console.log(HISTORY.location?.state, "courseName")

    const courseHeading = this.state.courseInfo?.attributes?.course_name
    return (
      <Grid container style={{ position: "relative" }}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#f8f9ff",
          }}
        >
          <div className="width-70per">
            <Grid
              container
              style={{
                display: "flex",
                position: "relative",
                padding: "20px 0",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              }}
            >
              <img
                src={logoSidePlaced}
                className="headerImageLogo"
                onClick={() => HISTORY.push("/")}
                style={{ cursor: "pointer" }}
              />

              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer",
                }}
                onClick={() => HISTORY.goBack()}
              >
                <Typography className="Course-Details-Title">
                  {courseHeading}
                </Typography>
                {/* <Button variant="outlined" color="primary" className="Change-Btn">
                                    <div className="Change-Text">Change</div>
                                </Button> */}
              </Grid>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <div style={{ display: "flex" }}>
                  <Button
                    // variant="outlined"
                    color="primary"
                    className="Free-Trial-Btn"
                    onClick={this.handleOpenZohoFormPopup}
                  >
                    <div className="Free-Trial-Course-Text">Book Free Trial</div>
                  </Button>
                  {/* <Button variant="contained" color="primary" className="Login-Course-Btn">
                                        <div className="Login-Course-Text">Login</div>
                                    </Button> */}
                </div>
              </Box>
            </Grid>
          </div>
        </Grid>
        <ZohoFormModalPopup
         open={this.state.openZohoForm}
         openThankyouModal={this.handleClickOpen}
         closeThankYou={this.handleClose}
         onCloseModal={this.handleCloseZohoFormPopup}
         isFirstTime={true}
        />
        <ThankYou
        open={this.state.open}
        handleClose={this.handleClose}
        />
      </Grid>
    );
  }
}
