// Customizable Area Start

import React from "react";
import {
  Button,
  Typography,
  Grid,
  Divider,
  Tabs,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";
import LandingPageController from "../LandingPage/LandingPageController.web";
import {
  onlineLearning,
  bitmap1,
  governmentDisable,
  exam,
  presentationEnable,
  rightArrow,
  leftArrow,
  videoEdition,
  videoMarketing,
  group9,
  books,
  bookk,
  Facetoface,
  instant,
  onlineTest,
  findd,
  webinar,
  rising,
  quote,
  developerActivity,
  CBSE_21,
  TNB_21,

} from "../assets";

import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "../CompetitiveExamPage/CompetetiveExamPage.web.css";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { KeyboardArrowDown } from "@material-ui/icons";
import { HISTORY } from "../../../../components/src/common";
import ZohoFormModalPopup from "../../../CustomForm/src/ZohoRegistrationFormModalPopup/ZohoFormModalPopup.web";
import ThankYou from "../../../CustomForm/src/ThankYou/ThankYou.web";

const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;
const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <img src={rightArrow} />,
  prevArrow: <img src={leftArrow} />,
  style: {
    padding: "75px 0",
  },
  responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
        arrows: true,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    boxShadow: "0 2px 4px 0 #d0d6f9",
    backgroundColor: theme.palette.background.paper,
    width: "110px",

    padding: "15px 0 10px 26px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Inter",
    color: "#5580ea",
    fontSize: 20,
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
const Reveal = require("react-reveal/Reveal");
// Customizable Area End

class TutionPage extends LandingPageController {
  obj = {
    0: {
      allsubject: (
        <Grid container direction="row">
          <Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
            <Grid
              container
            >
              {this.state.Class6AllsubjectTutions &&
                this.state.Class6AllsubjectTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 6",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course" title="CBSE" alt="CBSE image"
                        src={CBSE_21}

                      ></img>
                      <Grid
                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows" alt="Arrow"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    6: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class6MathsTutions &&
                this.state.Class6MathsTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 6",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      science: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class6ScienceTutions &&
                this.state.Class6ScienceTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 6",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="img-source"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      allsubject: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12} >
            <Grid
              container
            >
              {this.state.Class6AllsubjectTutions &&
                this.state.Class6AllsubjectTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 7",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="online-learning-img"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    7: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class7MathsTutions &&
                this.state.Class7MathsTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 7",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      science: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class7ScienceTutions &&
                this.state.Class7ScienceTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 7",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      allsubject: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12} >
            <Grid
              container
            >
              {this.state.Class7AllsubjectTutions &&
                this.state.Class7AllsubjectTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 8",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    8: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class8MathsTutions &&
                this.state.Class8MathsTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 8",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      science: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class8ScienceTutions &&
                this.state.Class8ScienceTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 8",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      allsubject: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class8AllsubjectTutions &&
                this.state.Class8AllsubjectTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 8",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    9: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class9MathsTutions &&
                this.state.Class9MathsTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 9",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      science: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class9ScienceTutions &&
                this.state.Class9ScienceTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 9",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      allsubject: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class9AllsubjectTutions &&
                this.state.Class9AllsubjectTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 9",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    10: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class10MathsTutions &&
                this.state.Class10MathsTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 10",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      science: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class10ScienceTutions &&
                this.state.Class10ScienceTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 10",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      allsubject: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class10AllsubjectTutions &&
                this.state.Class10AllsubjectTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 10",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    11: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class11Maths &&
                this.state.Class11Maths.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      physics: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class11Physics &&
                this.state.Class11Physics.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      chemistry: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class11Chemistry &&
                this.state.Class11Chemistry.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      biology: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class11Biology &&
                this.state.Class11Biology.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      accounts: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class11Accounts &&
                this.state.Class11Accounts.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      economics: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class11Economics &&
                this.state.Class11Economics.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      businessstudies: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            >
              {this.state.Class11BusinessStudies &&
                this.state.Class11BusinessStudies.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      sciencegroup: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class11ScienceGroup &&
                this.state.Class11ScienceGroup.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      commercegroup: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class11CommerceGroup &&
                this.state.Class11CommerceGroup.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    12: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12Maths &&
                this.state.Class12Maths.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      physics: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
              // justify="space-around"

              style={{ justifyContent: "flexstart" }}
            >
              {this.state.Class12Physics &&
                this.state.Class12Physics.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      chemistry: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12Chemistry &&
                this.state.Class12Chemistry.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      biology: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12Biology &&
                this.state.Class12Biology.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      accounts: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12Accounts &&
                this.state.Class12Accounts.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      economics: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12Economics &&
                this.state.Class12Economics.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      businessstudies: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12BusinessStudies &&
                this.state.Class12BusinessStudies.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      sciencegroup: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12ScienceGroup &&
                this.state.Class12ScienceGroup.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      commercegroup: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12CommerceGroup &&
                this.state.Class12CommerceGroup.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "CBSE",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={
                          this.state.selectedIndexTution === 1
                            ? CBSE_21
                            : this.state.selectedIndexTution === 2
                              ? TNB_21
                              : item.imgSource
                        }

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
  };
  obj1 = {
    0: {
      allsubject: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class6AllsubjectTutions &&
                this.state.Class6AllsubjectTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 6",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}

                      ></img>
                      {/* <Typography className="Course-Name">
                        {item.courseName}
                      </Typography> */}
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    6: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class6MathsTutions &&
                this.state.Class6MathsTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 6",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course" title="TN State Board" alt="TN State Board Images"
                        src={TNB_21}

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows" alt="Right Arrow"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      science: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class6ScienceTutions &&
                this.state.Class6ScienceTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 6",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      allsubject: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class6AllsubjectTutions &&
                this.state.Class6AllsubjectTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 6",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    7: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class7MathsTutions &&
                this.state.Class7MathsTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 7",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      science: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class7ScienceTutions &&
                this.state.Class7ScienceTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 7",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      allsubject: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class7AllsubjectTutions &&
                this.state.Class7AllsubjectTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 7",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    8: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class8MathsTutions &&
                this.state.Class8MathsTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 8",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      science: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class8ScienceTutions &&
                this.state.Class8ScienceTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 8",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      allsubject: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class8AllsubjectTutions &&
                this.state.Class8AllsubjectTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 8",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    9: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class9MathsTutions &&
                this.state.Class9MathsTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 9",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course" title="TNB" alt="TNB image"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      science: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class9ScienceTutions &&
                this.state.Class9ScienceTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 9",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      allsubject: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class9AllsubjectTutions &&
                this.state.Class9AllsubjectTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 9",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    10: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class10MathsTutions &&
                this.state.Class10MathsTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 10",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      science: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class10ScienceTutions &&
                this.state.Class10ScienceTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 10",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      allsubject: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class10AllsubjectTutions &&
                this.state.Class10AllsubjectTutions.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 10",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    11: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class11Maths &&
                this.state.Class11Maths.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      physics: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class11Physics &&
                this.state.Class11Physics.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      chemistry: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class11Chemistry &&
                this.state.Class11Chemistry.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      biology: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class11Biology &&
                this.state.Class11Biology.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      accounts: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class11Accounts &&
                this.state.Class11Accounts.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      economics: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class11Economics &&
                this.state.Class11Economics.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      businessstudies: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class11BusinessStudies &&
                this.state.Class11BusinessStudies.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      sciencegroup: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class11ScienceGroup &&
                this.state.Class11ScienceGroup.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      commercegroup: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class11CommerceGroup &&
                this.state.Class11CommerceGroup.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 11",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    12: {
      maths: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12Maths &&
                this.state.Class12Maths.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      physics: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
              // justify="space-around"

              style={{ justifyContent: "flexstart" }}
            >
              {this.state.Class12Physics &&
                this.state.Class12Physics.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      chemistry: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12Chemistry &&
                this.state.Class12Chemistry.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      biology: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12Biology &&
                this.state.Class12Biology.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      accounts: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12Accounts &&
                this.state.Class12Accounts.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      economics: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12Economics &&
                this.state.Class12Economics.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      businessstudies: (
        <Grid container direction="row">
          <Grid className="direction-row" item xs={12}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12BusinessStudies &&
                this.state.Class12BusinessStudies.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}
                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      sciencegroup: (
        <Grid container direction="row">
          <Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12ScienceGroup &&
                this.state.Class12ScienceGroup.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
      commercegroup: (
        <Grid container direction="row">
          <Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
            <Grid
              container
            // justify="space-around"
            >
              {this.state.Class12CommerceGroup &&
                this.state.Class12CommerceGroup.map((item: any) => (
                  <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                    <Grid
                      className="Courses-Details"
                      onClick={() =>
                        HISTORY.push({
                          pathname: "/CourseDetails",
                          state: {
                            program: "TN Board",
                            admission: "Class 12",
                            courseInfo: item.courseDetails,
                            courseName: item.courseName,
                            testimonials: [...this.state.tutionUserDetails],
                            isFrom: "tution",
                          },
                        })
                      }

                    >
                      <img className="image-course"
                        src={TNB_21}

                      ></img>
                      <Typography className="Course-Name">
                        {item.courseName}
                      </Typography>
                      <Grid

                        className="less-margin-small-screen"
                      >
                        <Typography className="Know-More-Text">
                          Know More
                        </Typography>
                        <img className="Arrows"
                          src={rightArrow}

                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
  };
  obj2 = {
    15: (
      <Grid container direction="row">
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid
            container
            //  justify="space-around"
            style={{ justifyContent: "flexstart" }}
          >
            {this.state.courseDetailsTutionsigcse &&
              this.state.courseDetailsTutionsigcse.map((item: any) => (
                <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                  <Grid
                    className="Courses-Details"
                    onClick={() =>
                      HISTORY.push({
                        pathname: "/CourseDetails",
                        state: {
                          program: "CBSE",
                          courseInfo: item.courseDetails,
                          courseName: item.courseName,
                          testimonials: [...this.state.tutionUserDetails],
                          isFrom: "tution",
                        },
                      })
                    }

                  >
                    <img className="image-course"
                      src={item.imgSource}

                    ></img>
                    <Typography className="Course-Name">
                      {item.courseName}
                    </Typography>
                    <Grid

                      className="less-margin-small-screen"
                    >
                      <Typography className="Know-More-Text">
                        Know More
                      </Typography>
                      <img className="Arrows"
                        src={rightArrow}

                      ></img>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    ),
    16: (
      <Grid container direction="row">
        <Grid className="direction-row"
          item
          xs={12}

        >
          <Grid
            container
            // justify="space-around"
            style={{ justifyContent: "flexstart" }}
          >
            {this.state.courseDetailsTutionsigcse2 &&
              this.state.courseDetailsTutionsigcse2.map((item: any) => (
                <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                  <Grid
                    className="Courses-Details"
                    onClick={() =>
                      HISTORY.push({
                        pathname: "/CourseDetails",
                        state: {
                          program: "CBSE",
                          courseInfo: item.courseDetails,
                          courseName: item.courseName,
                          testimonials: [...this.state.tutionUserDetails],
                          isFrom: "tution",
                        },
                      })
                    }

                  >
                    <img className="image-course"
                      src={item.imgSource}

                    ></img>
                    <Typography className="Course-Name">
                      {item.courseName}
                    </Typography>
                    <Grid

                      className="less-margin-small-screen"
                    >
                      <Typography className="Know-More-Text">
                        Know More
                      </Typography>
                      <img className="Arrows"
                        src={rightArrow}

                      ></img>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    ),
    17: (
      <Grid container direction="row">
        <Grid className="direction-row"
          item
          xs={12}

        >
          <Grid
            container
            // justify="space-around"
            style={{ justifyContent: "flexstart" }}
          >
            {this.state.courseDetailsTutionsigcse3 &&
              this.state.courseDetailsTutionsigcse3.map((item: any) => (
                <Grid item xs={12} sm={6} md={4} className="paddingRight20">
                  <Grid
                    className="Courses-Details"
                    onClick={() =>
                      HISTORY.push({
                        pathname: "/CourseDetails",
                        state: {
                          program: "CBSE",
                          courseInfo: item.courseDetails,
                          courseName: item.courseName,
                          testimonials: [...this.state.tutionUserDetails],
                          isFrom: "tution",
                        },
                      })
                    }

                  >
                    <img className="image-course"
                      src={item.imgSource}

                    ></img>
                    <Typography className="Course-Name">
                      {item.courseName}
                    </Typography>
                    <Grid

                      className="less-margin-small-screen"
                    >
                      <Typography className="Know-More-Text">
                        Know More
                      </Typography>
                      <img className="Arrows"
                        src={rightArrow}

                      ></img>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    ),
  };


  render() {

    const TuitionPageTitle = this.state.tuitionList?.attributes?.title
    const TuitionPageDescription = this.state.tuitionList?.attributes?.description
    const TuitionPageSectionOne = this.state.tuitionList?.attributes?.why_teachze?.description
    const TuitionPageSectionOneList = this.state.tuitionList?.attributes?.why_teachze?.feature_points
    const TuitionPageSectionTwoList = this.state.tuitionList?.attributes?.courses[0]?.course_hightlights?.feature_points
    const TuitionPageCBSEList = this.state.tuitionList?.attributes?.courses[0]?.courses?.data
    const seen = new Set();
    var CBSECLassList = TuitionPageCBSEList?.filter((item: any) => {
      const duplicate = seen.has(item?.attributes?.tuition_class);
      seen.add(item?.attributes?.tuition_class);
      return !duplicate;
    });


    const {
      tutionUserDetails,
      tabsListTution,
      selectedIndexTution,
      filteredTabsListTution,
    } = this.state;
    console.log(selectedIndexTution, "selectedIndexTution");
    const handleTutionMetaTags = () => {
      if (selectedIndexTution === 0) {
        return (
          <Helmet>
            <title>Teachze LIVE Online Classes | Math & Science Online Tuitions</title>
            <meta name="description" content="Get the best LIVE online tuitions for all subjects, especially Maths & Science of CBSE and TN Board for classes 6th – 12th. Teachze LIVE online classes are structured in a way that enables the children to understand the subject better and effective in a new way. Enroll today and book for a FREE trial live online class today!" />
          </Helmet>
        )
      } else if (selectedIndexTution === 1) {
        return (
          <Helmet>
            <title>Teachze LIVE Online Tuitions for CBSE Board | Classes 6th - 12th</title>
            <meta name="description" content="Teachze offers Face-to face LIVE interactive online tuitions for CBSE students in grades 6th – 12th. Learn from the best tutors and get better ranks and higher marks in all the exams. Experience the new way of effective learning with Teachze LIVE online classes. With proven methodologies, instant doubt solving sessions, flexible class timings and personalized attention, we help each students to understand and learn each subject better. Book a free trial LIVE online class for CBSE students today." />
          </Helmet>
        )
      } else {
        return (
          <Helmet>
            <title>Teachze LIVE Online Tuitions for TN Board | Classes 6th - 12th</title>
            <meta name="description" content="Teachze offers Face-to face LIVE interactive online tuitions for TN board students in grades 6th – 12th. Learn from the best tutors and get better ranks and higher marks in all the exams. Experience the new way of effective learning with Teachze LIVE online classes. With proven methodologies, instant doubt solving sessions, flexible class timings and personalized attention, we help each students to understand and learn each subject better. Book a free trial LIVE online class today." />
          </Helmet>
        )
      }
    }

    return (
      <div style={root}>
        {handleTutionMetaTags()}
        <Grid className="relative" container >
          <Grid className="Competitive-container-info"
            item
            xs={12}
            style={{
              paddingBottom: "2.5%",
            }}
          >
            <Reveal effect="fadeInUp">
              <div className="width-70per">
                <Grid container>
                  <Grid className="Competetive-info-left"
                    item
                    xs={12}
                    sm={8}
                    md={8}
                    style={{

                      width: "800px",
                    }}
                  >
                    <Typography variant="h1" className="Lets-Competetive-Ex-student-centric">
                      {TuitionPageTitle}
                    </Typography>
                    <Typography className="textlabel_description_1">
                      {TuitionPageDescription}
                    </Typography>

                    <Grid className="Search-Bg-Rectangle">
                      <div className="Search-Rectangle">
                        <img title="Search for course" src={bitmap1} alt="Search icon" className="Search-Img" />
                      </div>
                      <input
                        placeholder="Search for course"
                        className="Search-for-course"
                        value={this.state.inputTextTution}
                        onChange={(e) => {
                          this.setState({ inputTextTution: e.target.value });
                          if (
                            e.target.value === "" ||
                            e.target.value === null
                          ) {
                            this.setState({
                              filteredTabsListTution: tabsListTution,
                              selectedIndexTution: 0,
                            });
                          }
                        }}
                      />
                      <Button
                        className="Search-Btn"
                        onClick={this.searchTutionCourseClick}
                      >
                        <Typography className="Search-Text">Search</Typography>
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <img
                      src={onlineLearning}
                      alt="Student learning" title="Competitive Exam"
                      className="online-learning-img"
                    />
                  </Grid>
                </Grid>
              </div>
            </Reveal>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="width-70per">
              <Grid container>
                <Reveal effect="fadeInUp">
                  <Typography variant="h2" className="Courses">Courses</Typography>
                </Reveal>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                      <Reveal effect="fadeInLeft left cascade">
                        <div className="Courses-Rectangle">
                          <Grid container>
                            <Grid className="xs-4"
                              item
                              xs={4}
                              md={4}

                            >
                              <img
                                src={governmentDisable}
                                className="Courses-img" title="Competetive Exam" alt="image competetive "
                              />
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              md={8}
                              onClick={() =>
                                this.props.handleSelect("/Competitive-exams", 1)
                              }
                            >
                              <Typography className="Courses-Text">
                                Competitive Exam
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid container>
                            <Grid className="xs-4"
                              item
                              xs={4}
                              md={4}

                            >
                              <img
                                src={presentationEnable}
                                className="Courses-img" title="Tuitions" alt="image tuitions"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              md={8}
                              onClick={() =>
                                this.props.handleSelect("/Tuitions", 2)
                              }
                            >
                              <Typography
                                className="Courses-Text"
                                style={{ color: "#5580ea" }}
                              >
                                Tuitions
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid container>
                            <Grid className="xs-4"
                              item
                              xs={4}
                              md={4}

                            >
                              <img src={exam} className="Courses-img" title="Test Series" alt="image test" />
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              md={8}
                              onClick={() =>
                                this.props.handleSelect("/Test-series", 3)
                              }
                            >
                              <Typography className="Courses-Text">
                                Test Series
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </Reveal>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      <Reveal effect="fadeInRight right cascade">
                        <Grid container direction="row">
                          {selectedIndexTution === -1 ? (
                            <Grid>
                              <Typography
                                style={{
                                  fontFamily: "Inter",
                                  fontSize: 22,
                                  color: "#394052",
                                  marginBottom: 22,
                                }}
                              >
                                No course found.
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                position: "relative",
                              }}
                            >
                              <Tabs
                                value={"resources"}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                                variant="scrollable"
                                scrollButtons="auto"
                                TabScrollButtonProps={{
                                  style: {
                                    background: "#5580ea",
                                    color: "white",
                                    width: "30px",
                                    height: "30px",
                                    msTransform: "skewX(-6deg)",
                                    transform: "skewX(-6deg)",
                                    borderRadius: 5,
                                    margin: "0 5px",
                                    alignSelf: "center",
                                    opacity: 1,
                                  },
                                }}
                              >
                                {filteredTabsListTution.map((item: any) => (
                                  <Grid
                                    style={{
                                      height: 50,
                                      borderRadius: 5,
                                      backgroundColor:
                                        item.key === selectedIndexTution
                                          ? "#5580ea"
                                          : "#ecefff",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginRight: "25px",
                                      padding: "0 38px",
                                      margin: "3px",
                                      textTransform: "capitalize",
                                      cursor: "pointer",
                                      transform: "skewX(-6deg)",
                                    }}
                                    key={item.key}
                                    onClick={() => {
                                      this.handleSelectTabTution(
                                        item.value,
                                        item.key
                                      );
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontFamily: "Inter",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        textAlign: "center",
                                        transform: "skewX(6deg)",
                                        color:
                                          item.key === selectedIndexTution
                                            ? "white"
                                            : "#5580ea",
                                      }}
                                    >
                                      {item.label}
                                    </Typography>
                                  </Grid>
                                ))}
                              </Tabs>
                            </Grid>
                          )}
                        </Grid>
                        {selectedIndexTution === 1 ? (
                          <>
                            <Box className="main-box-for-class-and-subject">
                              <Select
                                className="selectclassmainbox"
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={this.state.currentClass}
                                input={<BootstrapInput />}
                                IconComponent={() => (
                                  <KeyboardArrowDown
                                    style={{
                                      position: "absolute",
                                      right: 8,
                                      top: 8,
                                      height: 30,
                                      width: 30,
                                      marginLeft: 10,
                                      color: "#5580ea",
                                      pointerEvents: "none",
                                    }}
                                  />
                                )}
                                onChange={(event: any) => {
                                  this.setState({
                                    selectedClass: event.target.value,
                                    currentClass: event.target.value,
                                  });
                                }}
                                style={{ margin: "1px 25px" }}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  style: {
                                    marginTop: "30px",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                <MenuItem
                                  value={0}
                                  className="selectclass"
                                  disabled
                                >
                                  Class
                                </MenuItem>

                                {CBSECLassList?.map((item: any) => (
                                  <MenuItem value={6} className="selectclass">
                                    {item.attributes.tuition_class}
                                  </MenuItem>
                                ))}
                              </Select>

                              <Grid>
                                {this.state.currentClass === 11 ||
                                  this.state.currentClass === 12 ? (
                                  <>
                                    <Select
                                      className="selectclassmainbox"
                                      labelId="demo-customized-select-label"
                                      id="demo-customized-select"
                                      value={this.state.selectedClass11to12}
                                      input={<BootstrapInput />}
                                      IconComponent={() => (
                                        <KeyboardArrowDown
                                          style={{
                                            position: "absolute",
                                            right: 8,
                                            top: 8,
                                            height: 30,
                                            width: 30,
                                            color: "#5580ea",
                                            pointerEvents: "none",
                                          }}
                                        />
                                      )}
                                      onChange={(event: any) => {
                                        this.setState({
                                          selectedClass11to12:
                                            event.target.value,
                                          currentSubject11to12:
                                            event.target.value,
                                        });
                                      }}
                                      style={{ margin: "1px 2px" }}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left",
                                        },
                                        style: {
                                          marginTop: "30px",
                                        },
                                        getContentAnchorEl: null,
                                      }}
                                    >
                                      <MenuItem
                                        value="maths"
                                        className="selectclass"
                                      >
                                        Maths
                                      </MenuItem>
                                      <MenuItem
                                        value="physics"
                                        className="selectclass"
                                      >
                                        Physics
                                      </MenuItem>
                                      <MenuItem
                                        value="chemistry"
                                        className="selectclass"
                                      >
                                        Chemistry
                                      </MenuItem>
                                      <MenuItem
                                        value="biology"
                                        className="selectclass"
                                      >
                                        Biology
                                      </MenuItem>
                                      <MenuItem
                                        value="accounts"
                                        className="selectclass"
                                      >
                                        Accounts
                                      </MenuItem>
                                      <MenuItem
                                        value="economics"
                                        className="selectclass"
                                      >
                                        Economics
                                      </MenuItem>
                                      <MenuItem
                                        value="businessstudies"
                                        className="selectclass"
                                      >
                                        Business Studies
                                      </MenuItem>
                                      <MenuItem
                                        value="sciencegroup"
                                        className="selectclass"
                                      >
                                        Science Group
                                      </MenuItem>
                                      <MenuItem
                                        value="commercegroup"
                                        className="selectclass"
                                      >
                                        Commerce Group
                                      </MenuItem>
                                    </Select>
                                  </>
                                ) : (
                                  <>
                                    <Select
                                      className="selectclassmainbox"
                                      labelId="demo-customized-select-label"
                                      id="demo-customized-select"
                                      value={this.state.selectedClassSecond}
                                      input={<BootstrapInput />}
                                      IconComponent={() => (
                                        <KeyboardArrowDown
                                          style={{
                                            position: "absolute",
                                            right: 8,
                                            top: 8,
                                            height: 30,
                                            width: 30,
                                            color: "#5580ea",
                                            pointerEvents: "none",
                                          }}
                                        />
                                      )}
                                      onChange={(event: any) => {
                                        this.setState({
                                          selectedClassSecond:
                                            event.target.value,
                                          currentSubject: event.target.value,
                                        });
                                      }}
                                      style={{ margin: "1px 2px" }}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left",
                                        },
                                        style: {
                                          marginTop: "30px",
                                        },
                                        getContentAnchorEl: null,
                                      }}
                                    >
                                      <MenuItem
                                        value="allsubject"
                                        className="selectclass"
                                      >
                                        All Subjects
                                      </MenuItem>
                                      <MenuItem
                                        value="maths"
                                        className="selectclass"
                                      >
                                        Maths
                                      </MenuItem>
                                      <MenuItem
                                        value="science"
                                        className="selectclass"
                                      >
                                        Science
                                      </MenuItem>
                                    </Select>
                                  </>
                                )}
                              </Grid>
                            </Box>
                          </>
                        ) : (
                          <></>
                        )}

                        {selectedIndexTution === 0 ||
                          selectedIndexTution === 1 ? (
                          <>
                            <Grid container>
                              <Typography variant="h3"
                                className="Courses-Header"

                              >
                                CBSE
                              </Typography>
                            </Grid>

                            <div>
                              {this.state.currentClass === 11 ||
                                this.state.currentClass === 12 ? (
                                <>
                                  {
                                    (this.obj as any)[this.state.currentClass][
                                    this.state.currentSubject11to12
                                    ]
                                  }
                                </>
                              ) : (
                                <>
                                  {
                                    (this.obj as any)[this.state.currentClass][
                                    this.state.currentSubject
                                    ]
                                  }
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {selectedIndexTution === 2 ? (
                          <>
                            <Box className="main-box-for-class-and-subject">
                              <Select
                                className="selectclassmainbox"
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={this.state.currentClass}
                                input={<BootstrapInput />}
                                IconComponent={() => (
                                  <KeyboardArrowDown
                                    style={{
                                      position: "absolute",
                                      right: 8,
                                      top: 8,
                                      height: 30,
                                      width: 30,
                                      marginLeft: 10,
                                      color: "#5580ea",
                                      pointerEvents: "none",
                                    }}
                                  />
                                )}
                                onChange={(event: any) => {
                                  this.setState({
                                    selectedClass: event.target.value,
                                    currentClass: event.target.value,
                                  });
                                }}
                                style={{ margin: "1px 25px" }}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  style: {
                                    marginTop: "30px",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                <MenuItem
                                  value={0}
                                  className="selectclass"
                                  disabled
                                >
                                  Class
                                </MenuItem>
                                <MenuItem value={6} className="selectclass">
                                  Class 6
                                </MenuItem>
                                <MenuItem value={7} className="selectclass">
                                  Class 7
                                </MenuItem>
                                <MenuItem value={8} className="selectclass">
                                  Class 8
                                </MenuItem>
                                <MenuItem value={9} className="selectclass">
                                  Class 9
                                </MenuItem>
                                <MenuItem value={10} className="selectclass">
                                  Class 10
                                </MenuItem>
                                <MenuItem value={11} className="selectclass">
                                  Class 11
                                </MenuItem>
                                <MenuItem value={12} className="selectclass">
                                  Class 12
                                </MenuItem>
                              </Select>

                              <Grid>
                                {this.state.currentClass === 11 ||
                                  this.state.currentClass === 12 ? (
                                  <>
                                    <Select
                                      className="selectclassmainbox"
                                      labelId="demo-customized-select-label"
                                      id="demo-customized-select"
                                      value={this.state.selectedClass11to12}
                                      input={<BootstrapInput />}
                                      IconComponent={() => (
                                        <KeyboardArrowDown
                                          style={{
                                            position: "absolute",
                                            right: 8,
                                            top: 8,
                                            height: 30,
                                            width: 30,
                                            color: "#5580ea",
                                            pointerEvents: "none",
                                          }}
                                        />
                                      )}
                                      onChange={(event: any) => {
                                        this.setState({
                                          selectedClass11to12:
                                            event.target.value,
                                          currentSubject11to12:
                                            event.target.value,
                                        });
                                      }}
                                      style={{ margin: "1px 2px" }}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left",
                                        },
                                        style: {
                                          marginTop: "30px",
                                        },
                                        getContentAnchorEl: null,
                                      }}
                                    >
                                      <MenuItem
                                        value="maths"
                                        className="selectclass"
                                      >
                                        Maths
                                      </MenuItem>
                                      <MenuItem
                                        value="physics"
                                        className="selectclass"
                                      >
                                        Physics
                                      </MenuItem>
                                      <MenuItem
                                        value="chemistry"
                                        className="selectclass"
                                      >
                                        Chemistry
                                      </MenuItem>
                                      <MenuItem
                                        value="biology"
                                        className="selectclass"
                                      >
                                        Biology
                                      </MenuItem>
                                      <MenuItem
                                        value="accounts"
                                        className="selectclass"
                                      >
                                        Accounts
                                      </MenuItem>
                                      <MenuItem
                                        value="economics"
                                        className="selectclass"
                                      >
                                        Economics
                                      </MenuItem>
                                      <MenuItem
                                        value="businessstudies"
                                        className="selectclass"
                                      >
                                        Business Studies
                                      </MenuItem>
                                      <MenuItem
                                        value="sciencegroup"
                                        className="selectclass"
                                      >
                                        Science Group
                                      </MenuItem>
                                      <MenuItem
                                        value="commercegroup"
                                        className="selectclass"
                                      >
                                        Commerce Group
                                      </MenuItem>
                                    </Select>
                                  </>
                                ) : (
                                  <>
                                    <Select
                                      className="selectclassmainbox"
                                      labelId="demo-customized-select-label"
                                      id="demo-customized-select"
                                      value={this.state.selectedClassSecond}
                                      input={<BootstrapInput />}
                                      IconComponent={() => (
                                        <KeyboardArrowDown
                                          style={{
                                            position: "absolute",
                                            right: 8,
                                            top: 8,
                                            height: 30,
                                            width: 30,
                                            color: "#5580ea",
                                            pointerEvents: "none",
                                          }}
                                        />
                                      )}
                                      onChange={(event: any) => {
                                        this.setState({
                                          selectedClassSecond:
                                            event.target.value,
                                          currentSubject: event.target.value,
                                        });
                                      }}
                                      style={{ margin: "1px 2px" }}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left",
                                        },
                                        style: {
                                          marginTop: "30px",
                                        },
                                        getContentAnchorEl: null,
                                      }}
                                    >
                                      <MenuItem
                                        value="allsubject"
                                        className="selectclass"
                                      >
                                        All Subjects
                                      </MenuItem>
                                      <MenuItem
                                        value="maths"
                                        className="selectclass"
                                      >
                                        Maths
                                      </MenuItem>
                                      <MenuItem
                                        value="science"
                                        className="selectclass"
                                      >
                                        Science
                                      </MenuItem>
                                    </Select>
                                  </>
                                )}
                              </Grid>
                            </Box>
                          </>
                        ) : (
                          <></>
                        )}
                        {selectedIndexTution === 0 ||
                          selectedIndexTution === 2 ? (
                          <>
                            <Grid container>
                              <Typography variant="h3"
                                className="Courses-Header"

                              >
                                TN State Board
                              </Typography>
                            </Grid>

                            <div>
                              {this.state.currentClass === 11 ||
                                this.state.currentClass === 12 ? (
                                <>
                                  {
                                    (this.obj1 as any)[this.state.currentClass][
                                    this.state.currentSubject11to12
                                    ]
                                  }
                                </>
                              ) : (
                                <>
                                  {
                                    (this.obj1 as any)[this.state.currentClass][
                                    this.state.currentSubject
                                    ]
                                  }
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {selectedIndexTution === 3 ? (
                          <>
                            <Box className="main-box-for-class-and-subject">
                              <Select
                                className="selectclassmainbox"
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={this.state.currentClassigcse}
                                input={<BootstrapInput />}
                                IconComponent={() => (
                                  <KeyboardArrowDown
                                    style={{
                                      position: "absolute",
                                      right: 8,
                                      top: 8,
                                      height: 30,
                                      width: 30,
                                      marginLeft: 10,
                                      color: "#5580ea",
                                      pointerEvents: "none",
                                    }}
                                  />
                                )}
                                onChange={(event: any) => {
                                  this.setState({
                                    currentClassigcse: event.target.value,
                                  });
                                }}
                                style={{ margin: "1px 25px" }}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  style: {
                                    marginTop: "30px",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                <MenuItem value={15} className="selectclass">
                                  JEE 1 Year
                                </MenuItem>
                                <MenuItem value={16} className="selectclass">
                                  JEE 2 Year
                                </MenuItem>
                                <MenuItem value={17} className="selectclass">
                                  JEE Crash Course
                                </MenuItem>
                              </Select>
                            </Box>
                          </>
                        ) : (
                          <></>
                        )}


                      </Reveal>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="spacing-100"
            style={{
              display: "flex",
              justifyContent: "center",
              background: "linear-gradient(180deg, #fff 50%, #f8f9ff 50%)",
              paddingBottom: 0,
            }}
          >
            <div className="width-70per">
              <Grid container>
                <Grid item xs={12}>
                  <div className="Course-Highlights-card">
                    <Reveal effect="fadeInUp">
                      <Grid
                        container
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="h2" className="Course-Highlights">
                          Course Highlights
                        </Typography>
                        <Typography variant="h3" className="Get-assistance-text">
                          {TuitionPageSectionOne}
                        </Typography>

                        <Grid
                          container
                          spacing={7}
                          alignItems="center"
                          style={{ marginTop: "20px" }}
                        >
                          {TuitionPageSectionTwoList?.map((item: any) => (
                            <Grid item md={4}>
                              <img title="" alt="image faculty"
                                src={item.attributes.description === " 100 hrs of live online class" ? bookk : item.attributes.description === " Proven Teaching Methodologies" ? Facetoface : item.attributes.description === " Personalized Mentorship" ? instant : item.attributes.description === " Enhance Problems solving and application skills" ? onlineTest : item.attributes.description === "Image Comprehensive study material and Question Bank" ? findd : item.attributes.description === " Integrated Test and Assessments" ? webinar : rising}
                                style={{
                                  height: 52,
                                  width: 52,
                                  objectFit: "contain",
                                }}
                              ></img>
                              <Typography
                                style={{
                                  fontFamily: "Inter",
                                  fontSize: "20.5px",
                                  fontWeight: 600,
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  color: "#464c5d",
                                  marginTop: "20px",
                                  width:
                                    item.text ===
                                      "Doubt resolution NEET experts"
                                      ? "60%"
                                      : "100%",
                                }}
                                className="md-font-18 xs-font-16"
                              >
                                {item.attributes.description}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Reveal>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>


          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="width-70per">
              <Reveal effect="fadeInUp">
                <Grid
                  container
                  className="spacing-100"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography variant="h2" className="Course-Highlights">
                    Why Teachze?
                  </Typography>
                  <Typography variant="h3"
                    className="Get-assistance-text"
                    style={{ margin: "2% 0" }}
                  >
                    {TuitionPageSectionOne}
                  </Typography>
                  <Grid item xs={12} style={{ marginTop: "2%" }}>
                    <Grid container spacing={6}>
                      {TuitionPageSectionOneList?.map((item: any) => (
                        <Grid item md={6}>
                          <Grid
                            container
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Grid item xs={2} sm={2} md={2} lg={1}>
                              <img alt="Online Live Classes"
                                src={item.attributes.title === "Interactive Online Live Classes" ? videoEdition : item.attributes.title === "Recorded videos of online classes" ? videoMarketing : item.attributes.title === "Dedicated online tutors – Individual attention" ? group9 : books}
                                style={{
                                  height: 35,
                                  width: 35,
                                  objectFit: "contain",
                                }}
                              ></img>
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              sm={10}
                              md={10}
                              lg={11}
                              style={{ paddingLeft: "1%" }}
                            >
                              <Typography variant="h4"
                                style={{
                                  fontFamily: "Inter",
                                  fontSize: "1.53vw",
                                  fontWeight: 600,
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  width: "90%",
                                  color: "#394052",
                                }}
                                className="md-font-20 sm-font-18"
                              >
                                {item.attributes.title}
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: 1.63,
                                  color: "#394052",
                                  width: "90%",
                                  marginTop: 20,
                                }}
                                className="xs-font-14"
                              >
                                {item.attributes.description}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Reveal>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="spacing-100"
            style={{
              display: "flex",
              justifyContent: "center",
              background: "#f8f9ff",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "15%",
                }}
              >
                <Reveal effect="fadeInLeft left cascade">
                  <div className="padding-right">
                    <Typography variant="h2" className="About-NEET-Text">
                      Bringing the classroom to you
                    </Typography>
                    <Typography variant="h3" className="This-platform-will-text">
                      Studies and Important Updates on the go. Now, don't miss
                      any Live Sessions or Mock Test Series. Indeed, quality
                      education is at your fingertips.
                    </Typography>
                    <ul>
                      <li className="view_box_content_text_li_2">
                        All Devices
                      </li>
                      <li className="ulList_2">
                        Get unlimited learning and study content from any
                        device, including mobile, tablet, and laptop.
                      </li>
                      <li className="view_box_content_text_li_2">
                        Track Your Progress
                      </li>
                      <li className="view_box_content_text_li_2">
                        Anywhere Access
                      </li>
                    </ul>
                    <Typography className="This-platform-will-text">
                    </Typography>
                    <Button
                      className="book-trial-btn-small"
                      onClick={this.handleOpenZohoFormPopup}
                    >
                      <Typography
                        style={{
                          fontFamily: "Inter",
                          fontSize: 18,
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          msTransform: "skewX(1deg)",
                          transform: "skewX(1deg)",
                          textTransform: "capitalize",
                        }}
                      >
                        Start Learning
                      </Typography>
                    </Button>
                  </div>
                </Reveal>
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="bitmap-img-align">
                <Reveal effect="fadeInRight right cascade">
                  <img src={developerActivity} className="bitmap-img" alt="Bitmap" ></img>
                </Reveal>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "3%",
            }}
          >
            <div className="width-70per">
              <Grid container style={{ position: "relative" }}>
                <Reveal effect="fadeInUp">
                  <img src={quote} className="quote-img"></img>
                  <Typography variant="h2" className="Hear-from-text">
                    Hear from our students and their parents
                  </Typography>
                </Reveal>
              </Grid>


              <Slider className="tiltImportant landing-carousel" {...settings}>
                {tutionUserDetails &&
                  tutionUserDetails.map((item: any) => (
                    <Grid style={{ padding: "5px" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="view_box"
                        style={{ height: "400px" }}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={8}
                            sm={8}
                            md={8}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              paddingLeft: 10,
                            }}
                          >
                            <Typography variant="h5" className="view_name">
                              {item.name}
                            </Typography>
                            <Typography variant="h6" className="view_parent">
                              {item.role}
                            </Typography>
                          </Grid>
                        </Grid>

                        <div className="view_line"></div>
                        <Typography className="view_text">
                          {item.text}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
        <ZohoFormModalPopup
          open={this.state.openZohoForm}
          openThankyouModal={this.handleClickOpen}
          closeThankYou={this.handleClose}
          onCloseModal={this.handleCloseZohoFormPopup}
          isFirstTime={true}
        />
        <ThankYou open={this.state.open} handleClose={this.handleClose} />
      </div>
    );
  }
}

export const competetiveExamPageStyles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ebebeb",
      },
    },
  },
};

export default TutionPage;
