import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { HISTORY } from "../../../../components/src/common";
import { runEngine } from "../../../../framework/src/RunEngine";
import {
  group28,
  onlineTest,
  rising,
  webinar,
  instant,
  videoEdition,
  videoMarketing,
  group9,
  books,
  user,
  book,
  school,
  editDocument,
  group28_2,
  group28_3,
  findd,
  Facetoface,
  subjectScience,
  subjectMaths,
  subjectChemistry,
  electronicCircuit,
  IGCSE_21,
  JEE_21,
  NEET_21,
  CA_21,
  FOUNDATION_21,
  NEETTS_21,
  imagePaper,
  book_gif,
  school_gif,
  editDocument_gif,
  Anywhere_learning,
  Self_paced_Learning_img,
  Vernacular_Coaching_img,
  Simplistic_Approach_img,
  Thought_Transparency_img,
  Interactive_Advanced_Platform_img,
  Detailed_Knowledge_Base_img,
} from "../assets";

export const configJSON = require("../config");

export interface Props {
  classes: any;
  navigation: any;
  tabIndex: number;
  handleSelect: any;
}

interface S {
  // Customizable Area Start
  open: boolean;
  tutionUserDetails: any;
  foundationUserDetails: any;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  data: any;
  bookOver: any;
  schoolOver: any;
  documentOver: any;
  bookShadow: any;
  schoolShadow: boolean;
  documentShadow: any;
  courseNeetDetails: any;
  courseDetailsTutions: any;
  Class6MathsTutions: any;
  Class6ScienceTutions: any;
  Class6AllsubjectTutions: any;
  Class7MathsTutions: any;
  Class7ScienceTutions: any;
  Class7AllsubjectTutions: any;
  Class8MathsTutions: any;
  Class8ScienceTutions: any;
  Class8AllsubjectTutions: any;
  Class9MathsTutions: any;
  Class9ScienceTutions: any;
  Class9AllsubjectTutions: any;
  Class10MathsTutions: any;
  Class10ScienceTutions: any;
  Class10AllsubjectTutions: any;
  Class11Maths: any;
  Class11Physics: any;
  Class11Chemistry: any;
  Class11Biology: any;
  Class11Accounts: any;
  Class11Economics: any;
  Class11BusinessStudies: any;
  Class11ScienceGroup: any;
  Class11CommerceGroup: any;
  Class12Maths: any;
  Class12Physics: any;
  Class12Chemistry: any;
  Class12Biology: any;
  Class12Accounts: any;
  Class12Economics: any;
  Class12BusinessStudies: any;
  Class12ScienceGroup: any;
  Class12CommerceGroup: any;
  courseDetailsTutionsigcse: any;
  courseDetailsTutionsigcse2: any;
  courseDetailsTutionsigcse3: any;
  courseDetailsTutions2: any;
  courseDetailsTestSeries: any;
  courseDetailsTestSeriesJee: any;
  courseDetailsIgcseTution: any;
  courseDetails: any;
  courseDetailsCA: any;
  courseDetailsJEE: any;
  courseDetailsFoundation: any;
  courseHighlights: any;
  courseHighlightsJEE: any;
  courseHighlightsCA: any;
  courseHighlightsPreFoundation: any;
  teachzePoints: any;
  userDetails: any;
  caUserDetails: any;
  tabsList: any;
  filteredTabsList: any;
  tabsListTution: any;
  filteredTabsListTution: any;
  tabsListTestSeries: any;
  filteredTabsListTestSeries: any;
  selectedIndex: number;
  mainTabIndex: number;
  selectedTab: string;
  selectedClass: any;
  timer: any;
  openZohoForm: boolean;
  examTaken: number;
  onlineExamTaken: number;
  videoLessons: number;
  minimumWatches: number;
  numberOfEducators: number;
  selectedIndexTution: number;
  selectedIndexClassTution: number;
  selectedIndexTestSeries: number;
  inputText: string;
  inputTextTution: string;
  selectedClassSecond: string;
  selectedClass11to12: string;
  selectedClassSecond1: string;
  currentClass: number;
  currentClassigcse: number;
  currentSubject11to12: string;
  currentSubject: string;
  inputTextTestSeries: string;
  openPopup: boolean;
  carouselCardData: any;
  courseList: any;
  courseInfo: any;
  LandingPageList: any;
  tuitionList: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {

  getCourseListID: string = "";
  getCourseInfoID: string = "";
  getLandingPageListID: string = "";
  getTuitionListID: string = "";
  interval: any;
  className: string;
  subjectName: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.get_course_list = this.get_course_list.bind(this);
    this.get_course_info = this.get_course_info.bind(this);
    this.get_tuition_list = this.get_tuition_list.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    this.className = "";
    this.subjectName = "";
    this.state = {
      open: false,
      selectedIndexTution: 1,
      tuitionList: [],
      LandingPageList: [],
      currentClass: 0,
      courseList: [],
      courseInfo: [],
      currentClassigcse: 15,
      currentSubject: "allsubject",
      currentSubject11to12: "maths",
      openPopup: true,
      selectedClassSecond: "allsubject",
      selectedClass11to12: "maths",
      selectedClassSecond1: "Jee1year",
      courseDetailsIgcseTution: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      data: {},
      bookOver: book,
      schoolOver: school,
      documentOver: editDocument,
      bookShadow: false,
      schoolShadow: false,
      documentShadow: false,
      openZohoForm: false,
      courseNeetDetails: [
        {
          imgSource: NEET_21,
          courseName: "NEET 1 Year",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectScience, name: "Biology" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: 48000,
            hours: "500+",
            description:
              "An intensive **face to face** live online interactive 1 year course for the students of class12 with emphasis on structured learning and Robust practice. Our course provides complete coverage of the topics in line with syllabus provided by the MCI (Medical Council of India) to make you best prepared in all three subjects - Physics, Chemistry & Biology.This course focuses on high conceptual clarity, enhancing skills and techniques to enable the students to succeed in NEET.",
          },
        },
        {
          imgSource: NEET_21,
          courseName: "NEET 2 Years",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectScience, name: "Biology" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: 70000,
            hours: "800+",
            description:
              "A comprehensive live online interactive 2 years course for the students of class11 with emphasis on structured learning and Robust practice. Our course provides complete coverage of the topics in line with syllabus provided by the MCI (Medical Council of India) to make you best prepared in all three subjects - Physics, Chemistry & Biology.This course focuses on high conceptual clarity, enhance skills and techniques to enable the students to succeed in NEET.",
          },
        },
        {
          imgSource: NEET_21,
          courseName: "NEET Crash Course",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectScience, name: "Biology" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: 19000,
            hours: "250+",
            description:
              "A brief live online interactive crash course for the students of class 12 with emphasis on structured learning and Robust practice. Our course provides complete coverage of the topics in line with syllabus provided by the MCI (Medical Council of India) to make you best prepared in all three subjects - Physics, Chemistry & Biology.This course focuses on high conceptual clarity, enhance skills and techniques to enable the students to succeed in NEET.",
          },
        },
        {
          imgSource: NEET_21,
          courseName: "NEET Repeater Course",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectScience, name: "Biology" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: 75000,
            hours: "900+",
            description:
              "A comprehensive live online interactive  repeated  course for the students who completed 12th  with emphasis on structured learning and Robust practice. Our course provides complete coverage of the topics in line with syllabus provided by the MCI (Medical Council of India) to make you best prepared in all three subjects - Physics, Chemistry & Biology.This course focuses on high conceptual clarity, enhance skills and techniques to enable the students to succeed in NEET.",
          },
        },

      ],
      courseDetailsTestSeries: [
        {
          imgSource: NEETTS_21,
          courseName: "NEET Compact Test Series",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectScience, name: "Biology" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: 'Free',
            hours: '0',
            description:
              "Teachze’s NEET Compact Test Series guides you to assess your preparedness and performance. NEET Test series is ideal for students who have almost completed their syllabus and would now want to brush up on their exam skills.",
          },
        },
        {
          imgSource: NEETTS_21,
          courseName: "NEET Mock Test Series",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: "Free",
            hours: '0',
            description:
              "Teachze’s NEET Mock Test Series guides you to assess your preparedness and performance. NEET Test series is ideal for students who are thorough with the syllabus and expects to sharpen the exam skills.",
          },
        },

      ],
      courseDetailsTestSeriesJee: [
        {
          imgSource: NEETTS_21,
          courseName: "JEE Compact Test Series",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectScience, name: "Biology" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: "Free",
            hours: "0",
            description:
              "Teachze’s JEE Compact Test Series guides you to assess your preparedness and performance. JEE Test series is ideal for students who have almost completed their syllabus and would now want to brush up on their exam skills.",
          },
        },
        {
          imgSource: NEETTS_21,
          courseName: "JEE Mock Test Series",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectChemistry, name: "Chemistry" },
              { imgSource: electronicCircuit, name: "Physics" },
            ],
            feeStructure: "Free",
            hours: "0",
            description:
              "Teachze’s JEE Mock Test Series guides you to assess your preparedness and performance. JEE Test series is ideal for students who are thorough with the syllabus and expects to sharpen the exam skills.",
          },
        },
      ],
      courseDetails: [
        {
          imgSource: group28,
          courseName: "Teachze NEET 2022 – One Year Course",
          courseText: "Know More",
        },
        {
          imgSource: group28,
          courseName: "Teachze NEET 2022 – One Year Course",
          courseText: "Know More",
        },
        {
          imgSource: group28,
          courseName: "Teachze NEET 2022 – One Year Course",
          courseText: "Know More",
        },
      ],
      courseDetailsCA: [
        {
          imgSource: CA_21,
          courseName: "CA Foundation",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              {
                imgSource: subjectMaths,
                name: "Principles and Practice of Accounting ",
              },
              {
                imgSource: subjectMaths,
                name: "Business Laws and Business Correspondence and Reporting",
              },
              {
                imgSource: subjectMaths,
                name: "Business Mathematics, Logical Reasoning and Statistics",
              },
              {
                imgSource: subjectMaths,
                name:
                  "Business Economics and Business and Commercial Knowledge",
              },
            ],
            feeStructure: 20000,
            hours: "450+",
            description:
              "Our courses provide complete coverage of the topics in line with ICAI (Institute of Chartered Accountants of India) exam syllabus to make you best prepared in all four subjects for the foundation examination and give ample time and guidance for the students to prepare to face the examination.We provide structured learning and robust practice by CA professional faculty Members. We conduct topic-wise and comprehensive test series and evaluate your progress to enhance exam preparation.",
          },
        },
      ],
      courseDetailsJEE: [
        {
          imgSource: JEE_21,
          courseName: "JEE 1 Year",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: 55000,
            hours: "500+",
            description:
              "An intensive live online interactive 1 year course for the students of class 12 with emphasis on structured learning and Robust practice. Our course provides complete coverage of the topics in line with syllabus provided by the NTA (National Testing Agency) to make you best prepared in all three subjects - Physics, Chemistry & Mathematics.This course focuses on high conceptual clarity, enhance skills and techniques to enable the students to succeed in JEE.",
          },
        },
        {
          imgSource: JEE_21,
          courseName: "JEE 2 Years",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: 80000,
            hours: "800+",
            description:
              "A comprehensive face to face online interactive 2 years course for the students of class 11 with emphasis on structured learning and Robust practice. Our course provides complete coverage of the topics in line with syllabus provided by the NTA (National Testing Agency) to make you best prepared in all three subjects - Physics, Chemistry & Mathematics.This course focuses on high conceptual clarity, enhance skills and techniques to enable the students to succeed in JEE.",
          },
        },
        {
          imgSource: JEE_21,
          courseName: "JEE Crash Course ",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: 25000,
            hours: "250+",
            description:
              "A brief live online interactive  crash  course for the students of class 12 with emphasis on structured learning and Robust practice. Our course provides complete coverage of the topics in line with syllabus provided by the NTA (National Testing Agency) to make you best prepared in all three subjects - Physics, Chemistry & Maths.This course focuses on high conceptual clarity, enhance skills and techniques to enable the students to succeed in JEE.",
          },
        },
      ],
      courseDetailsFoundation: [
        {
          imgSource: FOUNDATION_21,
          courseName: "Foundation Course Class 8",
          admission: "Foundation Class 8",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Science" },
            ],
            feeStructure: 14000,
            hours: "300+",
            description:
              "The programme covers all topics of Class VIII Mathematics and Science, thus preparing students for their school examinations, while developing a solid foundation for JEE and NEET. Certain advanced concepts and problem-solving strategies are also discussed.",
          },
        },
        {
          imgSource: FOUNDATION_21,
          courseName: "Foundation Course Class 9 ",
          admission: "Foundation Class 9 ",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Science" },
            ],
            feeStructure: 16000,
            hours: "300+",
            description:
              "The programme encloses all topics of Class IX Mathematics and Science, along with advanced concepts, so as to develop the students’ analytical-thinking ability and problem-solving skills. Also, we teach in Parallel on the concepts of competitive exams like NTSE, KVPY, NSTSE, Olympiads etc.",
          },
        },
        {
          imgSource: FOUNDATION_21,
          courseName: "Foundation Course class 10",
          admission: "Foundation Class 10",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Science" },
            ],
            feeStructure: 18000,
            hours: "300+",
            description:
              "The programme encloses all topics of Class X Mathematics and Science, along with advanced concepts, so as to develop the students’ analytical-thinking ability and problem-solving skills. Also, we teach in Parallel on the concepts of competitive exams like NTSE, KVPY, NSTSE, Olympiads etc.",
          },
        },

      ],
      courseDetailsTutions: [
        {
          imgSource: group28,
          courseName: "Class 6 - 8",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Biology" },
            ],
            feeStructure: 8000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 6 - 8 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
        {
          imgSource: group28_2,
          courseName: "Class 9 - 10",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Biology" },
            ],
            feeStructure: 9000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 9 - 10 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
        {
          imgSource: group28_3,
          courseName: "Class 11 - 12",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Biology" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 11 - 12 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class6MathsTutions: [
        {
          imgSource: group28,
          courseName: "Class 6 Maths",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Maths" }],
            feeStructure: 8000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 6 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class6ScienceTutions: [
        {
          imgSource: group28_2,
          courseName: "Class 6 Science ",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectScience, name: "Science" }],
            feeStructure: 8000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 6 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class6AllsubjectTutions: [
        {
          imgSource: group28_3,
          courseName: "Class 6 All Subject ",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Science" },
            ],
            feeStructure: 16000,
            hours: "200",
            description:
              "Get the best academic support from our online tutors to nourish your Class 6 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class7MathsTutions: [
        {
          imgSource: group28,
          courseName: "Class 7 Maths ",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Maths" }],
            feeStructure: 8000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 7 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class7ScienceTutions: [
        {
          imgSource: group28_2,
          courseName: "Class 7 Science ",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectScience, name: "Science" }],
            feeStructure: 8000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 7 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class7AllsubjectTutions: [
        {
          imgSource: group28_3,
          courseName: "Class 7 All Subject ",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Science" },
            ],
            feeStructure: 16000,
            hours: "200",
            description:
              "Get the best academic support from our online tutors to nourish your Class 7 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class8MathsTutions: [
        {
          imgSource: group28,
          courseName: "Class 8 Maths ",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Maths" }],
            feeStructure: 8000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 8 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class8ScienceTutions: [
        {
          imgSource: group28_2,
          courseName: "Class 8 Science ",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectScience, name: "Science" }],
            feeStructure: 8000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 8 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class8AllsubjectTutions: [
        {
          imgSource: group28_3,
          courseName: "Class 8 All Subject ",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Science" },
            ],
            feeStructure: 16000,
            hours: "200",
            description:
              "Get the best academic support from our online tutors to nourish your Class 8 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class9MathsTutions: [
        {
          imgSource: group28,
          courseName: "Class 9 Maths ",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Maths" }],
            feeStructure: 9000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 9 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class9ScienceTutions: [
        {
          imgSource: group28_2,
          courseName: "Class 9 Science ",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectScience, name: "Science" }],
            feeStructure: 9000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 9 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class9AllsubjectTutions: [
        {
          imgSource: group28_3,
          courseName: "Class 9 All Subject",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Science" },
            ],
            feeStructure: 18000,
            hours: "200",
            description:
              "Get the best academic support from our online tutors to nourish your Class 9 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class10MathsTutions: [
        {
          imgSource: group28,
          courseName: "Class 10 Maths  ",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Maths" }],
            feeStructure: 9000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 10 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class10ScienceTutions: [
        {
          imgSource: group28_2,
          courseName: "Class 10 Science ",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectScience, name: "Science" }],
            feeStructure: 9000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 10 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class10AllsubjectTutions: [
        {
          imgSource: group28_3,
          courseName: "Class 10 All Subject ",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Science" },
            ],
            feeStructure: 18000,
            hours: "200",
            description:
              "Get the best academic support from our online tutors to nourish your Class 10 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class11Maths: [
        {
          imgSource: group28,
          courseName: "Class 11 Maths ",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Maths" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 11 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class11Physics: [
        {
          imgSource: group28_2,
          courseName: "Class 11 Physics",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: electronicCircuit, name: "Physics" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 11 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class11Chemistry: [
        {
          imgSource: group28_3,
          courseName: "Class 11 Chemistry",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectChemistry, name: "Chemistry" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 11 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class11Biology: [
        {
          imgSource: group28,
          courseName: "Class 11 Biology",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectScience, name: "Biology" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 11 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class11Accounts: [
        {
          imgSource: group28_2,
          courseName: "Class 11 Accounts ",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Accounts" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 11 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class11Economics: [
        {
          imgSource: group28_3,
          courseName: "Class 11 Economics",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Economics" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 11 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class11BusinessStudies: [
        {
          imgSource: group28,
          courseName: "Class 11 Business Studies",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Business Studies" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 11 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class11ScienceGroup: [
        {
          imgSource: group28_2,
          courseName: "Class 11 Science Group",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Biology" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: 48000,
            hours: "480",
            description:
              "Get the best academic support from our online tutors to nourish your Class 11 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class11CommerceGroup: [
        {
          imgSource: group28_3,
          courseName: "Class 11 Commerce Group",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Accounts" },
              { imgSource: subjectMaths, name: "Economics" },
              { imgSource: subjectMaths, name: "Business Studies" },
              { imgSource: imagePaper, name: "English" },
            ],
            feeStructure: 36000,
            hours: "360",
            description:
              "Get the best academic support from our online tutors to nourish your Class 11 - 12 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class12Maths: [
        {
          imgSource: group28,
          courseName: "Class 12 Maths",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Maths" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 12 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class12Physics: [
        {
          imgSource: group28_2,
          courseName: "Class 12 Physics",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: electronicCircuit, name: "Physics" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 12 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],

      Class12Chemistry: [
        {
          imgSource: group28_3,
          courseName: "Class 12 Chemistry",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectChemistry, name: "Chemistry" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 12 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class12Biology: [
        {
          imgSource: group28,
          courseName: "Class 12 Biology",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectScience, name: "Biology" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 12 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class12Accounts: [
        {
          imgSource: group28_2,
          courseName: "Class 12 Accounts",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Accounts" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 11 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class12Economics: [
        {
          imgSource: group28_3,
          courseName: "Class 12 Economics",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Economics" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 12 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class12BusinessStudies: [
        {
          imgSource: group28,
          courseName: "Class 12 Business Studies ",
          courseText: "Know More",
          courseDetails: {
            subjects: [{ imgSource: subjectMaths, name: "Business Studies" }],
            feeStructure: 12000,
            hours: "120",
            description:
              "Get the best academic support from our online tutors to nourish your Class 12 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class12ScienceGroup: [
        {
          imgSource: group28_2,
          courseName: "Class 12 Science Group",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Biology" },
              { imgSource: electronicCircuit, name: "Physics" },
              { imgSource: subjectChemistry, name: "Chemistry" },
            ],
            feeStructure: 48000,
            hours: "480",
            description:
              "Get the best academic support from our online tutors to nourish your Class 12 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      Class12CommerceGroup: [
        {
          imgSource: group28_3,
          courseName: "Class 12 Commerce Group",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Accounts" },
              { imgSource: subjectMaths, name: "Economics" },
              { imgSource: subjectMaths, name: "Business Studies" },
              { imgSource: imagePaper, name: "English" },
            ],
            feeStructure: 36000,
            hours: "360",
            description:
              "Get the best academic support from our online tutors to nourish your Class 11 - 12 academic preparation. Teachze tutoring provides academic support for the CBSE. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],

      courseDetailsTutionsigcse: [
        {
          imgSource: IGCSE_21,
          courseName: "JEE 1 Year",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectChemistry, name: "Chemistry" },
              { imgSource: electronicCircuit, name: "Physics" },
            ],
            //feeStructure: 12000,
            //hours: "120",
            description:
              "An intensive live online interactive 1 year course for the students of class 12 with emphasis on structured learning and Robust practice. Our course provides complete coverage of the topics in line with syllabus provided by the NTA (National Testing Agency) to make you best prepared in all three subjects - Physics, Chemistry & Mathematics.This course focuses on high conceptual clarity, enhance skills and techniques to enable the students to succeed in JEE.",
          },
        },
      ],
      courseDetailsTutionsigcse2: [
        {
          imgSource: IGCSE_21,
          courseName: "JEE 2 Year",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectChemistry, name: "Chemistry" },
              { imgSource: electronicCircuit, name: "Physics" },
            ],
            //feeStructure: 12000,
            //hours: "120",
            description:
              "A comprehensive face to face online interactive 2 years course for the students of class 11 with emphasis on structured learning and Robust practice. Our course provides complete coverage of the topics in line with syllabus provided by the NTA (National Testing Agency) to make you best prepared in all three subjects - Physics, Chemistry & Mathematics.This course focuses on high conceptual clarity, enhance skills and techniques to enable the students to succeed in JEE.",
          },
        },
      ],
      courseDetailsTutionsigcse3: [
        {
          imgSource: IGCSE_21,
          courseName: "JEE Crash Year",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectChemistry, name: "Chemistry" },
              { imgSource: electronicCircuit, name: "Physics" },
            ],
            //feeStructure: 12000,
            //hours: "120",
            description:
              "A brief live online interactive  crash  course for the students of class 12 with emphasis on structured learning and Robust practice. Our course provides complete coverage of the topics in line with syllabus provided by the NTA (National Testing Agency) to make you best prepared in all three subjects - Physics, Chemistry & Maths.This course focuses on high conceptual clarity, enhance skills and techniques to enable the students to succeed in JEE.",
          },
        },
      ],

      courseDetailsTutions2: [
        {
          imgSource: group28,
          courseName: "Class 6 - 8",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Biology" },
            ],
            feeStructure: 8000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 6 academic preparation. Teachze tutoring provides academic support for the TN SBSE board. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
        {
          imgSource: group28_2,
          courseName: "Class 9 - 10",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Biology" },
            ],
            feeStructure: 8000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 6 - 8 academic preparation. Teachze tutoring provides academic support for the TN SBSE board. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
        {
          imgSource: group28_3,
          courseName: "Class 11 - 12",
          courseText: "Know More",
          courseDetails: {
            subjects: [
              { imgSource: subjectMaths, name: "Maths" },
              { imgSource: subjectScience, name: "Biology" },
            ],
            feeStructure: 8000,
            hours: "100",
            description:
              "Get the best academic support from our online tutors to nourish your Class 6 academic preparation. Teachze tutoring provides academic support for the TN SBSE board. We focus on your conceptual understanding and developing various skills like application, logical reasoning, problem-solving etc. Teachze provides a detailed performance analysis report which helps you track the learning progression of your child.",
          },
        },
      ],
      courseHighlights: [
      ],
      courseHighlightsJEE: [
      ],
      courseHighlightsCA: [
      ],
      courseHighlightsPreFoundation: [
      ],
      teachzePoints: [
      ],
      userDetails: [
        {
          imgSource: user,
          name: "C.A. Preethi",
          role: "NEET 1 yr",
          text:
            "The Teachze site is simple to use, and the practise worksheets provided before to the unit exam are helpful in preparing for the test. Dedicated tutors discuss question papers, identify faults, and clear up any doubts.",
        },
        {
          imgSource: user,
          name: "K. Thilakaraj",
          role: "NEET 1 yr",
          text:
            "The contents are easier for me to comprehend because of the in-depth presentation of ideas and explanation of concerns. The study material is intensive. ",
        },
        {
          imgSource: user,
          name: "G. Priyadarshini",
          role: "NEET 2 yr",
          text:
            "The online coaching sessions are quite interactive, and every weekend, doubt clearing sessions are held. Regular tests are given, and the recorded sessions are beneficial for review.",
        },
        {
          imgSource: user,
          name: "Mahalakshmi",
          role: "NEET 2 yr",
          text:
            "Teachers not only do a great job in class, but they also provide helpful tips for passing the NEET test. Weekly examinations are a great way to get some experience with various sorts of questions. Teachze is ready to listen to student queries and solve them immediately.",
        },
      ],
      foundationUserDetails: [
        {
          name: "Navya",
          role: "Class VIII, Chennai",
          text:
            "Teachze's Foundation course for Class VIII was fantastic, and the instructor was really clear in his communication. He was incredibly knowledgeable and patient in answering questions and explaining complex issues in simpler terms. I gained a lot of knowledge and felt Free to ask any questions I had.",
        },
        {
          name: "Akshaya S.",
          role: "Class VIII, Chennai",
          text:
            "Namasthe.. I took the Foundations class. It was very beneficial to me. It was about how to learn the topics more effectively. There are three processes to complete math sums. 1.Comprehension of ideas 2. connecting the concepts 3. Putting the formula into action. The Maths Session was quite instructive, and I gained much from it.",
        },
        {
          name: "S. Sharan",
          role: "Class VIII, Chennai",
          text:
            "The course is fantastic. It is good that our teachers create connections with us. I believe this course will be beneficial to us.",
        },
        {
          name: "R. Pavithra",
          role: "Class VIII, Chennai",
          text: "My son loves the sessions and I can already see the change.",
        },
      ],
      caUserDetails: [
        {
          imgSource: user,
          name: "G S MOHANA SUNDARAM",
          role: "Coimbatore",
          text:
            "All of the teachers are very knowledgeable and engage with all of the children in the classroom. The courses taught by Accounts instructor KAMESWARAN sir are the most fascinating. His teaching ability is outstanding... The recorded class video footages on the Teachze portal are really helpful for studying and revising...",
        },
        {
          imgSource: user,
          name: "V. Bhavishya",
          role: "Coimbatore",
          text:
            "Studying charted accounting courses on Teachze is very beneficial to me.And, maybe most importantly, Teachze provides us with recording lessons, allowing us to review whenever we want...",
        },
        {
          imgSource: user,
          name: "C. Kavirethinam",
          role: "Coimbatore",
          text:
            "CA Foundation classes are excellent and simple to comprehend. The professors communicate well in English and Tamil.",
        },
        {
          imgSource: user,
          name: "MOHANARAVINTH.S.M",
          role: "Coimbatore.",
          text:
            "The CA course faculty teaches well... the Teachze portal is better than other websites... Comparing to other institutions, the fees is very cheap...",
        },
        {
          imgSource: user,
          name: "S. DHINAKARAN",
          role: "Coimbatore",
          text:
            "The sessions are engaging, and the experienced lecturers provide excellent instruction to the students. As a result, I like the teacher's teaching methods and am more confident in my ability to become a chartered accountant.",
        },
      ],
      tutionUserDetails: [
        {
          imgSource: user,
          name: "D. Sandhiya",
          role: "Class XII, Sholingur",
          text:
            "Biology classes in Teachze – It's simple to get started with the portal, and I'm at ease with it. Classes are well-timed and prepared. Teachze has made an impression on me, and I am thankful.",
        },
        {
          imgSource: user,
          name: "T.S. Sivaranjani",
          role: "Class XII, Arakonam",
          text:
            "The way of teaching is very nice and good. Teachers are explaining our doubts clearly. The assignments are very useful for our preparations.",
        },
        {
          imgSource: user,
          name: "T. Lavanya",
          role: "Class XII, Sholingur",
          text:
            "Difficult subjects were well explained. We find teaching to be really intriguing, and we have a good understanding of it. Recorded sessions allow us to revisit the lessons. The ability to remove doubts was incredible. ",
        },
        {
          imgSource: user,
          name: "K. Thakshinamoorthy",
          role: "Class XII, Arakonam ",
          text:
            "Your study notes are very easy to understand and help us to score high marks. The daily tests and homework can be accessed very easily. Thanks for your support.",
        },
        {
          imgSource: user,
          name: "D. Shalini",
          role: "Class XI, Sholingur",
          text:
            "The instructor is highly involved and the teaching is excellent. We find the teaching technique to be quite engaging, and we have a good understanding of it. I am happy to learn from Teachze.",
        },
        {
          imgSource: user,
          name: "N. Senthil",
          role: "Class XI, Arakonam ",
          text:
            "Teachers respond quickly to our questions, and examinations are given on a regular basis. Revision lessons are both entertaining and convenient.",
        },
      ],

      carouselCardData: [
        {
          title: "Anywhere Learning",
          description: `Get access to Live Classes and quality education
          anytime and from anywhere.All you need is smooth
          internet connectivity.`,
          imgSrc: Anywhere_learning

        },
        {
          title: "Self-Paced Learning",
          description: `Students can learn at their own pace through
          recorded classes or Live online classes.`,
          imgSrc: Self_paced_Learning_img

        },
        {
          title: "Vernacular Coaching",
          description: `Students can access coaching in vernacular languages
          to understand the concepts better and encourage them
          to perform well.`,
          imgSrc: Vernacular_Coaching_img

        },
        {
          title: " Simplistic Approach",
          description: `Our classes are designed to provide students with a
          clear and strong fundamental understanding of
          complex subjects, making the syllabus simple.`,
          imgSrc: Simplistic_Approach_img

        },
        {
          title: "Thought Transparency",
          description: `Two-way student-teacher Interaction for better
          understanding of concepts. These classes are also
          superior to recorded videos.`,
          imgSrc: Thought_Transparency_img

        },
        {
          title: "Interactive Advanced Platform",
          description: `An AI-Based LMS Platform provides uninterrupted
          classes, 24*7 access to educational content, live
          classes, and proctored tests.`,
          imgSrc: Interactive_Advanced_Platform_img

        },
        {
          title: "Detailed Knowledge Base",
          description: `Have complete and accessible knowledge of the
          overall chapter from the specialists, from complex
          definitions to challenging problems.`,
          imgSrc: Detailed_Knowledge_Base_img

        }


      ],

      tabsList: [
        {
          key: 0,
          value: "all",
          label: "ALL",
        },
        {
          key: 1,
          value: "neet",
          label: "NEET",
        },
        {
          key: 2,
          value: "jee",
          label: "JEE",
        },
        {
          key: 3,
          value: "ca",
          label: "CA",
        },
        {
          key: 4,
          value: "Foundation",
          label: "Foundation",
        },
      ],
      filteredTabsList: [],
      tabsListTution: [
        // {
        //   key: 0,
        //   value: "all",
        //   label: "ALL",
        // },
        {
          key: 1,

          value: "CBSE",

          label: "CBSE",
        },
        {
          key: 2,
          value: "tn board",
          label: "TN Board",
        },
        // {
        //   key: 3,
        //   value: "IGCSE",
        //   label: "IGCSE",
        // },
      ],
      filteredTabsListTution: [],
      tabsListTestSeries: [
        {
          key: 0,
          value: "all",
          label: "ALL",
        },
        {
          key: 1,
          value: "NEET Test Series",
          label: "NEET Test Series",
        },
        {
          key: 2,
          value: "JEE Test Series",
          label: "JEE Test Series",
        },
      ],
      filteredTabsListTestSeries: [],
      selectedIndex: 0,
      mainTabIndex: 0,
      selectedClass: "class6",
      selectedTab: "/LandingPage",
      timer: null,
      examTaken: 3072,
      onlineExamTaken: 24,
      videoLessons: 350,
      minimumWatches: 800,
      numberOfEducators: 2000,
      selectedIndexClassTution: 1,
      selectedIndexTestSeries: 0,
      inputText: "",
      inputTextTution: "",
      inputTextTestSeries: "",
    };
    this.userdata = this.userdata.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleIndexforCompetetive = () => {
    if (window.location.toString().includes("/Competitive-exams/Neet")) {
      this.setState({
        selectedIndex: 1,
      });
    } else if (window.location.toString().includes("/Competitive-exams/Jee")) {
      this.setState({
        selectedIndex: 2,
      });
    } else if (window.location.toString().includes("/Competitive-exams/CA")) {
      this.setState({
        selectedIndex: 3,
      });
    } else if (
      window.location.toString().includes("/Competitive-exams/Foundation")
    ) {
      this.setState({
        selectedIndex: 4,
      });
    } else {
      this.setState({
        selectedIndex: 0,
      });
    }
  };
  handleIndexforTution = () => {
    if (window.location.toString().includes("/Tuitions/CBSE")) {
      this.setState({
        selectedIndexTution: 1,
      });
    } else if (window.location.toString().includes("/Tuitions/TN-Board")) {
      this.setState({
        selectedIndexTution: 2,
      });
    } else if (window.location.toString().includes("/Tuitions/Foundation")) {
      this.setState({
        selectedIndexTution: 3,
      });
    } else {
      this.setState({
        selectedIndexTution: 0,
      });
    }
  };
  handleIndexforTestSeries = () => {
    if (window.location.toString().includes("/Test-series/Neet")) {
      this.setState({
        selectedIndexTestSeries: 1,
      });
    } else if (window.location.toString().includes("/Test-series/Jee")) {
      this.setState({
        selectedIndexTestSeries: 2,
      });
    } else {
      this.setState({
        selectedIndexTestSeries: 0,
      });
    }
  };
  handleIndexforMainIndex = () => {
    if (window.location.toString().includes("/Competitive-exams")) {
      this.get_course_list("competitive_exam");
      this.setState({
        mainTabIndex: 1,
      });
    } else if (window.location.toString().includes("/Tuitions")) {
      this.get_course_list("tuition");
      this.setState({
        mainTabIndex: 2,
      });
    } else if (window.location.toString().includes("/Test-series")) {
      this.get_course_list("test_series");
      this.setState({
        mainTabIndex: 3,
      });
    } else {
      // this.get_course_list("test_series");
      this.setState({
        mainTabIndex: 0,
      });
    }
  };

  async componentDidMount() {
    this.setState({
      filteredTabsList: this.state.tabsList,
      filteredTabsListTution: this.state.tabsListTution,
      filteredTabsListTestSeries: this.state.tabsListTestSeries,
    });
    this.handleIndexforCompetetive();
    this.handleIndexforMainIndex();
    this.handleIndexforTution();
    this.handleIndexforTestSeries();
    this.get_landing_page_list();
    this.get_tuition_list();

    // this.get_course_list();
    // this.get_course_info();


  }

  kFormatter = (num: number) => {
    return Math.abs(num) > 999
      ? `${Math.sign(num) * Math.round(Math.abs(num) / 1000)} k`
      : `${Math.sign(num) * Math.abs(num)}`;
  };

  handleCloseZohoFormPopup = () => {
    this.setState({ openZohoForm: false });
  };

  handleOpenZohoFormPopup = () => {
    this.setState({ openZohoForm: true });
  };

  userdata = () => {
    const user_data = localStorage.getItem("user_data");
    const data = JSON.parse(user_data || "{}");
    console.log("user_data", data);
    this.setState({ data: data });
  };

  handleSelectTab = (tab: string, key: number) => {
    this.setState({
      selectedIndex: key,
    });
  };

  handleSelectTabTution = (tab: string, key: number) => {
    this.setState({
      selectedIndexTution: key,
    });
  };
  handleSelectClassTution = (tab: string, key: number) => {
    this.setState({
      selectedIndexClassTution: key,
    });
  };

  handleSelectTabTestSeries = (tab: string, key: number) => {
    this.setState({
      selectedIndexTestSeries: key,
    });
  };

  handleSelectMainTab = (tab: string, key: number) => {
    this.setState({
      selectedTab: tab,
      mainTabIndex: key,
    });
    HISTORY.push(tab);
  };

  get_course_list = (type: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseListID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.courseListEndPoint + `?course_content_name=${type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType

    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_tuition_list = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTuitionListID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.courseListEndPoint + `?course_content_name=tuition`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType

    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_course_info = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseInfoID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.courseInfoEndPoint + `?course_id=3`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType

    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  get_landing_page_list = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLandingPageListID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingPageEndPoint + `?site_page=home`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType

    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };




  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (apiRequestCallId === this.getCourseListID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ courseList: responseJson.data })
            var arr: any = []
            var arr1: any = []
            var arr2: any = []
            var arr3: any = []
            var teachZeeefeaturePoints: any = []
            responseJson.data?.attributes?.courses[0]?.course_hightlights?.feature_points?.map((item: any, index: any) => {
              let obj = {
                image: index === 0 ? Facetoface : index === 1 ? instant : index === 2 ? onlineTest : index === 3 ? findd : index === 4 ? webinar : rising,
                text: item.attributes.title,
              }
              arr.push(obj)
            })
            responseJson.data?.attributes?.courses[1]?.course_hightlights?.feature_points?.map((item: any, index: any) => {
              let obj = {
                image: index === 0 ? Facetoface : index === 1 ? instant : index === 2 ? onlineTest : index === 3 ? findd : index === 4 ? webinar : rising,
                text: item.attributes.title,
              }
              arr1.push(obj)
            })
            responseJson.data?.attributes?.courses[2]?.course_hightlights?.feature_points?.map((item: any, index: any) => {
              let obj = {
                image: index === 0 ? Facetoface : index === 1 ? instant : index === 2 ? onlineTest : index === 3 ? findd : index === 4 ? webinar : rising,
                text: item.attributes.title,
              }
              arr2.push(obj)
            })
            responseJson.data?.attributes?.courses[3]?.course_hightlights?.feature_points?.map((item: any, index: any) => {
              let obj = {
                image: index === 0 ? Facetoface : index === 1 ? instant : index === 2 ? onlineTest : index === 3 ? findd : index === 4 ? webinar : rising,
                text: item.attributes.title,
              }
              arr3.push(obj)
            })
            responseJson.data?.attributes?.why_teachze?.feature_points?.map((item: any, index: any) => {
              let obj = {
                imgSource: index === 0 ? videoEdition : index === 1 ? videoMarketing : index === 2 ? group9 : books,
                header: item.attributes.title,
                text: item.attributes.description
              }
              teachZeeefeaturePoints.push(obj)
            })

            this.setState({
              courseHighlights: arr,
              courseHighlightsJEE: arr1,
              courseHighlightsCA: arr2,
              courseHighlightsPreFoundation: arr3,
              teachzePoints: teachZeeefeaturePoints
            })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.getTuitionListID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ tuitionList: responseJson.data })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.getCourseInfoID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ courseInfo: responseJson.data })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.getLandingPageListID) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            this.setState({ LandingPageList: responseJson.data })
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  getJustifyContent = (index: number) => {
    if (index === 0) {
      return "flex-start";
    } else if (index === 2) {
      return "flex-end";
    } else {
      return "center";
    }
  };

  searchCourseClick = () => {
    const filterTabsList = this.state.tabsList.filter((tab: any) => {
      if (this.state.inputText === "") {
        return tab;
      } else if (
        tab &&
        tab.label.toLowerCase().includes(this.state.inputText.toLowerCase())
      ) {
        return tab;
      }
    });
    if (filterTabsList?.length > 0) {
      this.setState({ selectedIndex: filterTabsList[0]?.key });
    } else {
      this.setState({ selectedIndex: -1 });
    }

    this.setState({ filteredTabsList: filterTabsList });
  };

  searchTutionCourseClick = () => {
    const filterTabsList = this.state.tabsListTution.filter((tab: any) => {
      if (this.state.inputTextTution === "") {
        return tab;
      } else if (
        tab &&
        tab.label
          .toLowerCase()
          .includes(this.state.inputTextTution.toLowerCase())
      ) {
        return tab;
      }
    });
    if (filterTabsList?.length > 0) {
      this.setState({ selectedIndexTution: filterTabsList[0]?.key });
    } else {
      this.setState({ selectedIndexTution: -1 });
    }

    this.setState({ filteredTabsListTution: filterTabsList });
  };

  searchTestSeriesCourseClick = () => {
    const filterTabsList = this.state.tabsListTestSeries.filter((tab: any) => {
      if (this.state.inputTextTestSeries === "") {
        return tab;
      } else if (
        tab &&
        tab.label
          .toLowerCase()
          .includes(this.state.inputTextTestSeries.toLowerCase())
      ) {
        return tab;
      }
    });
    if (filterTabsList?.length > 0) {
      this.setState({ selectedIndexTestSeries: filterTabsList[0]?.key });
    } else {
      this.setState({ selectedIndexTestSeries: -1 });
    }

    this.setState({ filteredTabsListTestSeries: filterTabsList });
  };

  competitiveExamCardMouseHover = () => {
    this.setState({
      bookShadow: true,
      bookOver: book_gif,
    });
  }
  competitiveExamCardMouseLeave = () => {
    this.setState({ bookShadow: false, bookOver: book });
  }

  schoolTutionCardMouseHover = () => {
    this.setState({
      schoolShadow: true,
      schoolOver: school_gif,
    });

  }

  schoolTutionMouseLeave = () => {
    this.setState({
      schoolShadow: false,
      schoolOver: school,
    });
  }

  examTestSeriesMouseHover = () => {
    this.setState({
      documentShadow: true,
      documentOver: editDocument_gif,
    });

  }

  examTestSeriesMouseLeave = () => {
    this.setState({
      documentShadow: false,
      documentOver: editDocument,
    });

  }

}
