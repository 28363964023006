import React from "react";
import LandingPageController from "../LandingPage/LandingPageController.web";
import LandingPageHeader from "../LandingPage/LandingPageHeader/LandingPageHeader.web";
import LandingPageFooter from "../LandingPage/LandingPageFooter/LandingPageFooter.web";
import HomePageFooter from "../LandingPage/HomePageFooter.web";
import TutionPageWeb from "./TutionPage.web";

const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;

export default class TutionMainPage extends LandingPageController {
  render() {
    const { mainTabIndex } = this.state;
    return (
      <div style={root}>
        <LandingPageHeader
          mainTabIndex={mainTabIndex}
          handleSelectMainTab={this.handleSelectMainTab}
        />
        <TutionPageWeb
          classes={undefined}
          navigation={undefined}
          tabIndex={0}
          handleSelect={this.handleSelectMainTab}
        />
        {mainTabIndex === 0 ? (
          <HomePageFooter mainTabIndex={0} handleSelectMainTab={undefined} />
        ) : (
          <LandingPageFooter mainTabIndex={0} handleSelectMainTab={undefined} />
        )}
      </div>
    );
  }
}
