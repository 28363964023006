import React from "react";
import LandingPageController from "../../../dashboard/src/LandingPage/LandingPageController.web";
import LandingPageHeader from "../../../dashboard/src/LandingPage/LandingPageHeader/LandingPageHeader.web";
import LandingPageFooter from "../../../dashboard/src/LandingPage/LandingPageFooter/LandingPageFooter.web";
import AboutUs from "./AboutUs.web";
const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;

export default class AboutUsMainPage extends LandingPageController {
  render() {
    return (
      <div style={root}>
        <LandingPageHeader
          mainTabIndex={4}
          handleSelectMainTab={this.handleSelectMainTab}
        />
        <AboutUs navigation={undefined} />
        <LandingPageFooter mainTabIndex={0} handleSelectMainTab={undefined} />
      </div>
    );
  }
}
