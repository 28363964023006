import React from "react";
import LandingPageController from "./LandingPageController.web";
import { Box } from "@material-ui/core";
import LandingPageHeader from "./LandingPageHeader/LandingPageHeader.web";
import LandingPageFooter from "./LandingPageFooter/LandingPageFooter.web";
import HomePageFooter from "./HomePageFooter.web";
import LandingPage from "./LandingPage.web";
import "../LandingPage.web.css";
const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;

export default class LandingMainPage extends LandingPageController {
  render() {
    const { mainTabIndex } = this.state;
    return (
      <Box style={root}>
        <LandingPageHeader
          mainTabIndex={mainTabIndex}
          handleSelectMainTab={this.handleSelectMainTab}
        />
        <LandingPage
          classes={undefined}
          navigation={undefined}
          tabIndex={0}
          handleSelect={this.handleSelectMainTab}
        />
        {mainTabIndex === 0 ? (
          <HomePageFooter mainTabIndex={0} handleSelectMainTab={undefined} />
        ) : (
          <LandingPageFooter mainTabIndex={0} handleSelectMainTab={undefined} />
        )}
      </Box>
    );
  }
}
