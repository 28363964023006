// Customizable Area Start

import React, { useState } from "react";
import { Grid, Modal, Typography } from "@material-ui/core";
import { calendar } from "../../assets";
import { Close } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import moment from "moment";

interface Props {
// Customizable Area Start
  onCloseModal: any;
  open: any;
  scheduleList: any;
  // Customizable Area End
}

// Customizable Area End

export class CustomDateFnsUtils extends DateFnsUtils {
  getWeekdays() {
    return ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  }
}

const ScheduleDetails = ({ onCloseModal, open, scheduleList }: Props) => {
  const handleClose = () => {
    onCloseModal();
  };
  const scrollToEvents = (elementId: string) => {
    if (elementId) {
      const element = document.getElementById(elementId);
      if (element)
        element.scrollIntoView({
          block: "start",
        });
    }
  };
  const [selectedDate, setSelectedDate] = useState<any>(moment(new Date()));
  const handleDateChange = (data: any) => {
    const setDateFormat = moment(data.toDate());
    setSelectedDate(setDateFormat);

    const date = setDateFormat.format("DD-MM-YYYY");
    scrollToEvents(`date-${date}`);
    // const test = moment(date).isSame(moment("03-01-2022"))
    // console.log('test',test)
  };

  return (
    <Modal
      open={!!open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          borderRadius: "20px",
          // height: "80%",
          width: "70%",
          padding: "3%",
        }}
        className="schedule-modal"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "2%",
          }}
        >
          <Typography className="Educator-Profile">Schedule</Typography>
          <div className="close-rectangle" onClick={handleClose}>
            <Close style={{ color: "#fff", cursor: "pointer" }} />
          </div>
        </div>

        <Grid container className="schedule-bg-block">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Typography className="schedule-title">
              Batch for Class 11th JEE Main & Advanced 2023
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            className="small-screen-justify-start"
          >
            <Typography className="schedule_startdate">
              Start Date: 2nd January,2022
            </Typography>
          </Grid>
        </Grid>

        <Grid container style={{ padding: "3% 0" }}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <div className="scheduleCalendar">
              <div className="selected_date_view_box">
                <img src={calendar}></img>
                <span className="selected_date">
                  {moment(selectedDate).format("DD MMMM,YYYY")}
                </span>
              </div>

              <MuiPickersUtilsProvider utils={CustomDateFnsUtils}>
                <DatePicker
                  disableToolbar
                  variant="static"
                  format="DD-MM-YYYY"
                  okLabel="Save"
                  value={selectedDate}
                  onChange={(e) => handleDateChange(e)}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={8} className="list-style">
            {scheduleList &&
              scheduleList.map((item: any, index: number) => {
                let showDateHeader = true;
                if (item.date === scheduleList[index - 1]?.date) {
                  showDateHeader = false;
                }

                return (
                  <>
                    {showDateHeader && (
                      <Typography
                        className="schedule-date-header"
                        id={`date-${moment(item.date).format("DD-MM-YYYY")}`}
                      >
                        {item.date}
                      </Typography>
                    )}
                    <Grid
                      container
                      className="lecture-bg-box"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderLeft: `8px solid ${item.borderLeftColor}`,
                      }}
                    >
                      <Grid
                        item
                        xs={8}
                        sm={8}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Typography className="lecture-title">
                          {item.title}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 11,
                          }}
                        >
                          <Typography
                            className="unit-and-time-text"
                            style={{ width: "40%" }}
                          >
                            {item.unit}
                          </Typography>
                          <Typography className="unit-and-time-text">
                            {item.time}
                          </Typography>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sm={4}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={item.lecturerImage}
                          className="lecturer-image"
                        ></img>
                        <Typography className="lecturer-name">
                          {item.lecturerName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
export default ScheduleDetails;
