import React from "react";
import LandingPageController from '../../../dashboard/src/LandingPage/LandingPageController.web'
import LandingPageHeader from "../../../dashboard/src/LandingPage/LandingPageHeader/LandingPageHeader.web";
import LandingPageFooter from "../../../DynamicContent/src/Blog/BlogPageFooter.web";
import Technology from "./Technology.web";

const root = {
    flexGrow: 1,
    overflowX: "hidden",
    position: "relative"
} as const;

export default class TechnologyMainPage extends LandingPageController {
    
    render() {

        return (
            <div style={root}>
                <LandingPageHeader mainTabIndex={4} handleSelectMainTab={this.handleSelectMainTab} />
                <Technology navigation={undefined} />
                <LandingPageFooter mainTabIndex={0} handleSelectMainTab={undefined} />
            </div>
        );
    }
}


