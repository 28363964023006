import React from "react";

import LandingPageController from '../../../dashboard/src/LandingPage/LandingPageController.web'
import LandingPageHeader from "../../../dashboard/src/LandingPage/LandingPageHeader/LandingPageHeader.web";
import LandingPageFooter from "../../../DynamicContent/src/Blog/BlogPageFooter.web";
import PrivacyPolicy from "./PrivacyPolicy.web";

import { HISTORY } from "../../../../components/src/common";

const root = {
    flexGrow: 1,
    overflowX: "hidden",
    position: "relative"
} as const;

export default class PrivacyPolicyMainPage extends LandingPageController {
   
    render() {
        const { mainTabIndex } = this.state

        return (
            <div style={root}>
                <LandingPageHeader mainTabIndex={-1} handleSelectMainTab={() => {
                    HISTORY.push("/")
                    this.handleSelectMainTab
                    }} />
                <PrivacyPolicy navigation={undefined}/>
                <LandingPageFooter mainTabIndex={0} handleSelectMainTab={undefined} />
            </div>
        );
    }
}

