import React from "react";
import { withRouter } from 'react-router-dom'
import {
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import BlogPageController from "./BlogController.web";
import {
  search,
  leftArrow,
  rightArrow,
} from '../../../dashboard/src/assets'
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "./BlogPage.web.css";
import { HISTORY } from "../../../../components/src/common";
import { createBrowserHistory } from 'history';
const root = {
  flexGrow: 1,
  overflowX: "hidden",
  position: "relative",
} as const;
const settingsForTeacherBlock = {
  dots: false,
  infinite: false,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  nextArrow: <img src={rightArrow} />,
  prevArrow: <img src={leftArrow} />,
  style: {
    padding: "5% 0",
  },
  responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Reveal = require("react-reveal/Reveal");
class BlogPage extends BlogPageController {

  history = createBrowserHistory();
  render() {
    const push = (category: string, data: any) => {
     
      HISTORY.push(`/Blogdetail/${category}/${data.title}`, data)
    }
    
    const BlogLanguage = this.state.blogList[0]?.attributes?.name
    const BlogTechnology = this.state.blogList[1]?.attributes?.name
    const BlogLatest = this.state.blogList[2]?.attributes?.name
    const LanguageBlogList = this.state.blogList[0]?.attributes?.blog_list
    const TechnologyBlogList = this.state.blogList[1]?.attributes?.blog_list
    const LatestBlogList = this.state.blogList[2]?.attributes?.blog_list
    const languageDate = this.state.blogList[0]?.attributes?.created_at
    const displayDate = new Date()
   
    
    return (
      <div style={root}>
        <Helmet>
          <title>Blog | Best LIVE Online Tuitions and Coaching Classes - Teachze</title>
          <meta name="description" content="Know more about Teachze and the services provided in digital learning platform. Give it a read and know how our LIVE Online classes help the students exactly. Get instant updates on Languages and study, technologies and latest news related to online learning and live online classes." />
        </Helmet>
        <Grid container style={{ position: "relative" }}>
          <Grid className="heading-blog"
            item
            xs={12}
            >
            <div className="width-70per">
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Reveal effect="fadeInUp">
                    <div className="pl">
                      <Typography variant="h1" className="textlabel_heading">
                        Teachze Blog
                      </Typography>
                    </div>
                  </Reveal>
                </Grid>
              </Grid>
            </div>

          </Grid>
          <Grid
            item
            xs={12}
          >
            <Reveal effect="fadeInUp">
              <div className="dis-jcenter">
                <div className="width-70per">
                  <Grid container>
                    <Grid item xs={12} sm={8} md={6}>
                      <div className="Search-Bg-Rectangle_blog">
                        <input
                          placeholder="Search Blog.."
                          className="Search-for-course_blog"
                        />
                        <Button variant="contained" color="primary" className="search-Btn-icon">
                          <img title="Search Blog.."
                            src={search}
                            alt="Search icon"
                            className="Search-Img-icon"
                          />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Reveal>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="width-70per">
              <Grid  container style={{ display: "flex"}}>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={10}
                >
                  <Reveal effect="fadeInRight right cascade"><div >
                    <Typography variant="h2" className="textlabel_batches_blog">
                    {BlogLanguage}
                    </Typography></div></Reveal>
                </Grid>

                <Grid container item xs={12} >
                  <Reveal effect="fadeInUp">
                   
                       <Grid container spacing={3} style={{ marginTop : "30px", marginBottom : "30px"}}>
                      {LanguageBlogList?.map((item: any) => (
                             <Grid item xs={12} sm={6} md={4}>
                          <Grid onClick={() => {
                            
                            push("Languages and study", item?.blog_id)
                          }
                          } className="batch-view-box-blog">
                                  <div className="container">
                                    <img title="Bridging the Educational Inequality" alt="Bridging image"
                                      src={item.imageSource}
                                      style={{
                                        width: "100%",
                                        objectFit: "contain",
                                      }}
                                    ></img>
                              <div className="topright">{displayDate.toLocaleDateString("en-US", item.created_at)}</div>
                                  </div>

                                  <Grid className="batch-view-box-blog-content">

                                    <Typography variant="h3" className="textlabel_blog">
                                      {item.title}
                                    </Typography>


                                    

                                  </Grid>
                                </Grid>
                            </Grid>
                           
                        ))}
                          </Grid>
                    
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid className="dis-jcenter"
            item
            xs={12}
            
          >
            <div className="width-70per" style={{ position: "relative" }}>
              <Grid className="direction-row" container>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={10}
                >
                  <Reveal effect="fadeInRight right cascade">
                    <div className="direction-column" >
                      <Typography variant="h2" className="textlabel_batches_blog">
                        {BlogTechnology}
                      </Typography>
                    </div>
                  </Reveal>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                >
                </Grid>
              </Grid>

              <Grid container direction="row" style={{ padding: "3% 0" }}>
                <Grid
                  item
                  xs={12}
                >
                  <Reveal effect="fadeInUp">
                    <div className="direction-row">
                      <Grid container style={{ cursor: "pointer" }}  spacing={3} >
                        {TechnologyBlogList?.map((item: any) => (
                            <Grid item xs={12} sm={6} md={4}>
                              <Grid className="batch-view-box-blog" onClick={() => push("Technology", item?.blog_id)}>
                                <div className="container">
                                  <img className="image-course" title="Digitizing Education" alt="Digitizing Education image"
                                    src={item.imageSource}
                                    
                                  ></img>
                                  <div className="topright">{displayDate.toLocaleDateString("en-US", item.created_at)}</div>
                                </div>
                                <Grid className="batch-view-box-blog-content">

                                  <Typography variant="h3" className="textlabel_blog">
                                    {item.title}
                                  </Typography>

                                  
                                  

                                </Grid>

                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    </div>
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid className="dis-jcenter"
            item
            xs={12}
            
          >
            <div className="width-70per" style={{ position: "relative" }}>
              <Grid className="direction-row" container>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={10}
                > <Reveal effect="fadeInRight right cascade"> <div className="direction-column">
                  <Typography variant="h3" className="textlabel_batches_blog">
                  {BlogLatest}
                  </Typography>
                </div>
                  </Reveal>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                >
                  
                </Grid>
              </Grid>

              <Grid container direction="row" style={{ padding: "3% 0" }}>
                <Grid
                  item
                  xs={12}
                >  <Reveal effect="fadeInUp">
                   

                    <div className="direction-row">
                      <Grid container  spacing={3}>
                        {LatestBlogList?.map((item: any) => (
                            <Grid item xs={12} sm={6} md={4}>
                              <Grid className="batch-view-box-blog" onClick={() => push("Latest News", item?.blog_id)} style={{ cursor: "pointer" }}>
                                <div className="container">
                                  <img className="image-course" title="Latest News" alt="Blog image"
                                    src={item.imageSource}
                                   
                                  ></img>
                                  <div className="topright">{displayDate.toLocaleDateString("en-US", item.created_at)}</div>
                                </div>
                                <Grid className="batch-view-box-blog-content">

                                  <Typography variant="h3" className="textlabel_blog">
                                    {item.title}
                                  </Typography>
                                  
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    </div>
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>

    );
  }
}

export const BlogPageStyles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ebebeb",
      },
    }
  }
}

export default  withRouter( BlogPage);

