import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  data: any;
  selectedForItem: string;
  forStudentList: any;
  forParentList: any;
  forSocietyList: any;
  forItemsList: any;
  selectedEducator: any;
  isOpenEducatorDetails: boolean;
  selectedLeader: any;
  aboutUsList: any;
  teamMembersList: any;
}

interface SS {
  id: any;
}

export default class AboutUsController extends BlockComponent<Props, S, SS> {
  getAboutUsListID: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.get_about_us_list = this.get_about_us_list.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: "",
      aboutUsList: [],
      txtSavedValue: "A",
      enableField: false,
      teamMembersList: [],
      data: {},
      isOpenEducatorDetails: false,

      selectedForItem: "student",
      forStudentList: [
        "Learning made more interactive and creative",
        "Independent and easy learning",
        "Expert faculty for student-centered learning",
        "Comfortable learning environment",
        "Face-to-face classes to enrich learning",
      ],
      forParentList: [
        "Unlimited access to top-notch curriculum",
        "Strong involvement in student’s progress",
        "Cost-effective e-learning programs",
        "Traditional learning with modern technology",
        "Less anxiety and continuous tracking of progress",
      ],
      forSocietyList: [
        "Reaching to maximum students",
        "No carbon footprint",
        "Consistent tracking of students’ progress",
        "Better retention",
        "Anytime, anywhere easy learning",
      ],
      forItemsList: [],
      selectedEducator: {
        id: "1",
        teacherName: "Vijay Sinha",
        qualifications: "M Tech , IIT Bombay",
        experience:
          "18 years experience of teaching.Mathematics graduation from Delhi University",
        no_of_likes: 3000,
      },

      selectedLeader: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.setState({ forItemsList: this.state.forStudentList });
    this.get_about_us_list();
  }

  get_about_us_list = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAboutUsListID = requestMessage.messageId;
    let apiEndPoint = configJSON.aboutUsEndpoint + `?site_page=about_us`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getAboutUsListID) {
        if (responseJson != null) {
          if (!responseJson.error) {
            debugger
            this.setState({
              aboutUsList: responseJson?.data?.data,
              teamMembersList: responseJson?.team_members?.data,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }

      if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
        let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

        this.showAlert(
          "Change Value",
          "From: " + this.state.txtSavedValue + " To: " + value
        );

        this.setState({ txtSavedValue: value });
      }
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;
}
