Object.defineProperty(exports, "__esModule", {
  value: true,
});

const urlConfig = require("../../../framework/src/config");

// Customizable Area Start
exports.apiMethod = "POST";
exports.validationApiContentType = "application/json";
exports.examinationUrl = urlConfig.examinationUrl;
exports.dashboardURL = urlConfig.dashboardURL;
exports.instituteURL = urlConfig.instituteURL;
exports.baseURL = urlConfig.baseURL;
exports.attendanceURL = urlConfig.attendanceURL;
exports.competitiveExamListEndPoint = "/bx_block_contentmanagement/course_info";
exports.courseListEndPoint = "bx_block_contentmanagement/course_contents/courses_list";
exports.courseInfoEndPoint = "bx_block_contentmanagement/course_contents/course_info";
exports.landingPageEndPoint = "bx_block_contentmanagement/home_page";
exports.zohoFormLeadPostUrl = "/account_block/accounts/submit_data";
exports.dashboarApiMethodType = "GET";
exports.dashboarContentType = "application/json";
// Customizable Area End
