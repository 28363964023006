import React from "react";
import { Box, Button, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { vectorMask } from "../../../dashboard/src/assets";
import LandingPageHeaderController, {
  Props,
} from "../../../dashboard/src/LandingPage/LandingPageHeaderController.web";
import ZohoFormModalPopup from "../../../CustomForm/src/ZohoRegistrationFormModalPopup/ZohoFormModalPopup.web";
import Footer from "../../../dashboard/src/Components/Footer/Footer.web";
import ThankYou from "../../../CustomForm/src/ThankYou/ThankYou.web";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default class BlogPageFooter extends LandingPageHeaderController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundImage: `url(${vectorMask})`,
              backgroundSize: "contain",
              width: "100%",
            }}
            p={{ xs: "1.5rem", sm: "3rem", md: "5rem" }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={8} md={8}>
                    <Typography
                      className="Start-Learning-with-footer"
                      variant="h2"
                    >
                      Start Learning with Teachze
                    </Typography>
                    <Typography
                      className="Discover-a-new-way-footer"
                      variant="subtitle2"
                    >
                      Discover a new way of Learning! Welcome to Teachze's
                      unique dynamic and interactive classes within a top-notch
                      educational environment
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Button
                      style={{
                        padding: "0.8rem",
                        fontWeight: "bold",
                        transform: "skewX(-6deg)",
                        borderRadius: "10px",
                        width: "217px",
                        height: "61px",
                        backgroundColor: "white",
                      }}
                      className="book-a-free-trial-small-btn"
                      variant="outlined"
                      onClick={this.handleOpenZohoFormPopup}
                    >
                      <span id="trial" className="BOOK-A-FREE-TRIAL-footer">
                        BOOK A FREE TRIAL
                      </span>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Footer />
        </Box>
        <ZohoFormModalPopup
          open={this.state.openZohoForm}
          openThankyouModal={this.handleClickOpen}
          closeThankYou={this.handleClose}
          onCloseModal={this.handleCloseZohoFormPopup}
          isFirstTime={true}
        />
        <ThankYou open={this.state.open} handleClose={this.handleClose} />
      </React.Fragment>
    );
  }
}
