import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";

type Props = {
  content: string;
};

const BlogDetailsTemplate = (props: Props) => {
  useEffect(() => {
    console.log("jhvj");
  }, [props.content]);

  return (
    <Grid container spacing={1}>
      <div dangerouslySetInnerHTML={{ __html: props.content }} />
    </Grid>
  );
};
export default BlogDetailsTemplate;
