import React from "react";
import { withRouter} from "react-router-dom";
import { Button, Typography, Grid, Box } from "@material-ui/core";
import { search} from "../../../dashboard/src/assets";
import "slick-carousel/slick/slick.css";
import "./Blogdetail.web.css";
import BlogDetailsTemplate from "./BlogDetailsTemplate";
import { HISTORY } from "../../../../components/src/common";
import BlogDetailsController from "./BlogDetailsController.web";
const Reveal = require("react-reveal/Reveal");

class Blogdetail extends BlogDetailsController {
  render() {
    const { params } = this.props.match;
    const push = (category: string, data: any) => {
      HISTORY.push(`/Blogdetail/${category}/${data.title}`, data);
    };

    const latestBlog = this.state.blogInfo?.attributes?.latest_blogs?.data
    const date = this.state.blogInfo?.attributes?.created_at
    let displayDate = new Date()

    return (
      <div>
        <Grid container className="firstbox">
          <Grid item xs={12} className="top-light-blue-box">
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className="teachze-blog-parent-grid"
              >
                {" "}
                <Reveal effect="fadeInUp">
                  <div className="padding-right width-70per">
                    <Typography variant="h1" className="textlabel_heading">
                      Teachze Blog
                    </Typography>
                  </div>
                </Reveal>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Reveal effect="fadeInUp">
              <div className="dis-jcenter">
                <div className="width-70per">
                  <Grid container>
                    <Grid item xs={12} sm={8} md={6}>
                      <div className="Search-Bg-Rectangle_blog">
                        <input
                          placeholder="Search Blog.."
                          className="Search-for-course_blog"
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          className="search-Btn-icon"
                        >
                          <img title="Search Blog.."
                            src={search}
                            alt="Search icon"
                            className="Search-Img-icon"
                          />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Reveal>
          </Grid>

          <Grid className="dis-jcenter"
            item
            xs={12}
            
          >
            <div className="width-70per">
              <Grid container style={{ paddingTop: "3%", paddingBottom: "3%" }}>
                <Grid item xs={12} sm={12} md={8}>
                  <Reveal effect="fadeInLeft left cascade">
                    <div className="imageabove-text-blog">
                      <span style={{ fontWeight: 600, color: "#394052" }}>
                        {" "}
                        Home {">"} Blog {">"} {params.category} {">"}{" "}
                      </span>
                      {this.state.blogInfo?.attributes?.title}
                    </div>

                    <img title="Bridging the Educational Inequality"
                      src={this.state.blogInfo?.attributes?.image}
                      alt="Bridging image"
                      className="image-rep-blog"
                    />

                    <h2 className="imagebelow-text-blog">{this.state.blogInfo?.attributes?.title}</h2>
                    <Box className="yellow-button-box" style={{marginBottom: 25}}>
                      <button className="yellow-button">{displayDate.toLocaleDateString("en-US",date)}</button>
                    </Box>
                  </Reveal>

                  <BlogDetailsTemplate content={this.state.blogInfo?.attributes?.content} />
                  <Reveal effect="fadeInLeft left cascade">
                    <Box className="leave-comment">Leave a comment</Box>
                    <div className="Line"></div>

                    <Grid container className="width_90">
                      <Grid item xs={12}>
                        <Grid container className="name-email-box" spacing={4}>
                          <Grid item xs={12} sm={6}>
                            <Box className="name">
                              {" "}
                              Name <div className="astrik">*</div>
                            </Box>
                            <input className="name-input" type="Name" />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box className="email-box">
                              {" "}
                              E-mail<div className="astrik">*</div>
                            </Box>
                            <input className="email-box-input" type="text" />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box className="Comment-box">Comment</Box>
                            <input className="comment-box-text" type="text" />
                          </Grid>
                        </Grid>
                        <Box className="send-button">
                          <span style={{ transform: "skew(1deg)" }}>Send</span>
                        </Box>
                      </Grid>
                    </Grid>
                  </Reveal>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Reveal effect="fadeInRight right cascade">
                    

                    <Box className="latest-news">
                      <h4 className="latest-news-test">Latest News </h4>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          {latestBlog &&
                            latestBlog.map((item: any) => (
                                <Box
                                  className="subject00"
                                  onClick={() => {
                                    push("Technology", item)
                                    this.get_blog_info(item.id)
                                  }}
                                >
                                  <img
                                    alt="subject"
                                    src={item?.attributes?.image}
                                    className="listitem_subjectlogooo"
                                  />

                                  <span className="listitem_subjectnameoo">
                                    {item?.attributes?.title}
                                  </span>
                                </Box>
                              ))}
                          
                        </Grid>
                      </Grid>
                    </Box>
                  </Reveal>
                </Grid>
              </Grid>
            </div>
          </Grid>

        </Grid>
      </div>
    );
  }
}

export default withRouter(Blogdetail);
