Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  const urlConfig = require("../../../../framework/src/config");
  
  // Customizable Area Start
  exports.apiMethod = "POST";
  exports.validationApiContentType = "application/json";
  exports.examinationUrl = urlConfig.examinationUrl;
  exports.dashboardURL = urlConfig.dashboardURL;
  exports.instituteURL = urlConfig.instituteURL;
  exports.baseURL = urlConfig.baseURL;
  exports.attendanceURL = urlConfig.attendanceURL;
  exports.competitiveExamListEndPoint = "/bx_block_contentmanagement/course_info";
  exports.aboutUsEndpoint = "bx_block_contentmanagement/course_contents/site_pages";
  exports.zohoFormLeadPostUrl = "/account_block/accounts/submit_data";
  exports.courseInfoEndPoint = "bx_block_contentmanagement/course_contents/course_info";
  exports.dashboarApiMethodType = "GET";
  exports.dashboarContentType = "application/json";
  // Customizable Area End
  