import { IconButton, Grid } from "@material-ui/core";
import React from "react";
import { facebook, instagram, twitter, youtube } from "./assets";
function SocialMedia() {
  return (
    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingTop: "20px",
      }}
    >
      <a
        target="__blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/Teachze/"
      >
        <IconButton color="primary" size="small">
          <img
            src={facebook}
            className="social-media-img"
            alt="facebook icon"
          />
        </IconButton>
      </a>
      <a
        target="__blank"
        rel="noopener noreferrer"
        href="http://instagram.com/teachze/"
      >
        <IconButton color="primary" size="small">
          <img
            src={instagram}
            className="social-media-img"
            alt="instagram icon"
          />
        </IconButton>
      </a>
      <a
        target="__blank"
        rel="noopener noreferrer"
        href="https://twitter.com/teachze_inc?t=WdPpZjtuZSRqIYsobo1-AA&s=08"
      >
        <IconButton color="primary" size="small">
          <img src={twitter} className="social-media-img" alt="twitter icon" />
        </IconButton>
      </a>
      <a
        target="__blank"
        rel="noopener noreferrer"
        href="https://youtube.com/channel/UCD-le5J5npVo1uZHsHajEbA"
      >
        <IconButton color="primary" size="small">
          <img src={youtube} className="social-media-img" alt="youtube icon" />
        </IconButton>
      </a>
    </Grid>
  );
}

export default SocialMedia;
