import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";


export const configJSON = require("./config.js");


export interface Props {
  
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
    enableField: boolean;
    footerList: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FooterContoller extends BlockComponent<Props, S, SS> {
    getFooterListID: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.get_footer_list = this.get_footer_list.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]

    this.state = {
        txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      footerList: [],
         
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    //   this.setState({ forItemsList: this.state.forStudentList });
      this.get_footer_list();
  }

  get_footer_list = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFooterListID = requestMessage.messageId;
    let apiEndPoint = configJSON.footerEndpoint;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.baseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
  
  
          if (apiRequestCallId === this.getFooterListID) {
                if (responseJson != null) {
                  if (!responseJson.errors) {
                        this.setState({ footerList: responseJson.data})
                        console.log('footerList', this.state.footerList)
                    } else {
                        this.parseApiErrorResponse(responseJson);
                    }
                }
                this.parseApiCatchErrorResponse(errorReponse);
                // this.setState({ loading: false });
            }

            if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
                let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

                this.showAlert(
                    "Change Value",
                    "From: " + this.state.txtSavedValue + " To: " + value
                );

                this.setState({ txtSavedValue: value });
            }
            // Customizable Area End
        }
    }

  // Customizable Area Start
 

  // Customizable Area End
}
